import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { motion } from 'framer-motion';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import {
  Trophy,
  Clock,
  Target,
  Flag,
  ArrowRight,
  CheckCircle,
  XCircle,
  BarChart2,
  BookOpen,
  ArrowLeft,
  ExternalLink,
  AlertTriangle,
  Info,
} from 'lucide-react';
import { calculateDomainScores } from '../../lib/examMode';

// Score threshold for passing
const PASSING_SCORE = 70;

// Helper component for domain scores in exam mode
const DomainScoreCard = ({ domain, score = 0, weight = 0, questionsCount = 0 }) => {
  // Ensure we have valid numbers with fallbacks
  const safeScore = typeof score === 'number' && !isNaN(score) ? score : 0;
  const safeWeight = typeof weight === 'number' && !isNaN(weight) ? weight : 0;
  const safeQuestionsCount = typeof questionsCount === 'number' && !isNaN(questionsCount) ? questionsCount : 0;

  return (
    <Card className="bg-white/90 backdrop-blur-md">
      <CardContent className="p-6">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="font-medium text-gray-900">{domain || 'Unknown Domain'}</h3>
            <p className="text-sm text-gray-500">
              {safeWeight}% of exam • {safeQuestionsCount} questions
            </p>
          </div>
          <div className={`text-lg font-bold ${
            safeScore >= PASSING_SCORE ? 'text-green-600' : 'text-red-600'
          }`}>
            {safeScore.toFixed(1)}%
          </div>
        </div>
        
        <div className="mt-4">
          <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
            <div 
              className={`h-full transition-all duration-500 ${
                safeScore >= PASSING_SCORE ? 'bg-green-500' : 'bg-red-500'
              }`}
              style={{ width: `${Math.max(0, Math.min(100, safeScore))}%` }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const ExamResultScreen = () => {
  const { examName, attemptId } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [examData, setExamData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadExamResult = () => {
      try {
        const reviewData = localStorage.getItem('reviewExam');
        if (reviewData) {
          const parsed = JSON.parse(reviewData);
          if (!parsed?.exam?.content?.questions) {
            throw new Error('Invalid exam data');
          }

          // If exam mode, recalculate domain scores
          if (parsed.exam.content.mode === 'exam') {
            const scores = calculateDomainScores(
              parsed.attempt.answers,
              parsed.exam.content.questions
            );
            parsed.attempt.domainScores = scores;
          }

          setExamData(parsed);
          setLoading(false);
          return;
        }

        // If not in review storage, find in user's saved exams
        const savedExam = user?.savedExams?.find(exam => 
          Object.values(exam.domains || {}).some(domainData => 
            domainData.attempts?.some(a => a.id === attemptId)
          )
        );

        if (!savedExam) throw new Error('Exam not found');

        // Find specific attempt
        let foundAttempt = null;
        let foundDomain = null;

        Object.entries(savedExam.domains || {}).some(([domain, domainData]) => {
          const attempt = domainData.attempts?.find(a => a.id === attemptId);
          if (attempt) {
            foundAttempt = attempt;
            foundDomain = domain;
            return true;
          }
          return false;
        });

        if (!foundAttempt || !foundDomain) {
          throw new Error('Attempt not found');
        }

        const examDataForStorage = {
          exam: savedExam,
          attempt: {
            ...foundAttempt,
            domain: foundDomain,
            domainScores: foundAttempt.domainScores || {}
          }
        };

        setExamData(examDataForStorage);
        setLoading(false);

      } catch (error) {
        console.error('Error loading exam result:', error);
        navigate('/account');
      }
    };

    if (user) {
      loadExamResult();
    }
  }, [attemptId, user, navigate]);

  if (loading || !examData) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const { exam, attempt } = examData;
  const isExamMode = exam.content?.mode === 'exam';
  const domainScores = attempt.domainScores || {};

  const handleRetake = () => {
    const retakeExam = {
      exam: {
        ...exam,
        content: {
          ...exam.content,
          domain: attempt.domain
        }
      },
      startTime: Date.now()
    };
    localStorage.setItem('currentExam', JSON.stringify(retakeExam));
    navigate('/practice-test');
  };

  const handlePracticeDomains = () => {
    const currentExam = {
      exam: {
        ...exam,
        content: {
          ...exam.content,
          mode: 'practice'
        }
      },
      startTime: Date.now()
    };
    localStorage.setItem('currentExam', JSON.stringify(currentExam));
    navigate(`/test-mode/${exam.metadata.identifier.certificationCode}`);
    };

  // Calculate correct answers for display
  const correctAnswers = exam.content.questions.reduce((count, question, index) => {
    const userAnswers = attempt.answers[index] || [];
    const isCorrect = JSON.stringify([...userAnswers].sort()) === 
                     JSON.stringify([...question.answers].sort());
    return isCorrect ? count + 1 : count;
  }, 0);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        {/* Header */}
        <div className="mb-8">
          <Button
            variant="ghost"
            className="mb-4"
            onClick={() => navigate('/account')}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Dashboard
          </Button>
          
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-bold text-gray-800 mb-2">
                {exam.metadata?.identifier?.displayName}
              </h1>
              <div className="flex items-center text-gray-600">
                <Target className="w-4 h-4 mr-2" />
                <span>
                  {isExamMode ? 'Full Exam' : `Domain: ${attempt.domain}`}
                </span>
              </div>
            </div>
            <div className="flex items-center mt-4 md:mt-0">
              <Clock className="w-5 h-5 text-gray-500 mr-2" />
              <span className="text-gray-600">{attempt.timeTaken}</span>
            </div>
          </div>
        </div>

        {/* Score Overview */}
        <Card className="bg-white/90 backdrop-blur-md mb-8">
          <CardContent className="p-8">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex items-center mb-4 md:mb-0">
                <div className="relative">
                  <Trophy className={`w-16 h-16 ${
                    attempt.score >= PASSING_SCORE ? 'text-yellow-500' : 'text-gray-400'
                  }`} />
                  <div className={`absolute -top-2 -right-2 rounded-full w-6 h-6 flex items-center justify-center ${
                    attempt.score >= PASSING_SCORE 
                      ? 'bg-green-500 text-white' 
                      : 'bg-red-500 text-white'
                  }`}>
                    {attempt.score >= PASSING_SCORE ? '✓' : '!'} 
                  </div>
                </div>
                <div className="ml-4">
                  <h2 className="text-4xl font-bold text-gray-800">
                    {attempt.score.toFixed(1)}%
                  </h2>
                  <p className={`text-sm ${
                    attempt.score >= PASSING_SCORE ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {attempt.score >= PASSING_SCORE ? 'Pass' : 'Need More Practice'}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="text-center">
                  <div className="text-2xl font-bold text-gray-800">
                    {exam.content.questions.length}
                  </div>
                  <div className="text-sm text-gray-600">Questions</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-gray-800">
                    {attempt.flaggedQuestions?.length || 0}
                  </div>
                  <div className="text-sm text-gray-600">Flagged</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-gray-800">
                    {isExamMode ? Object.keys(domainScores).length : 1}
                  </div>
                  <div className="text-sm text-gray-600">Domains</div>
                </div>
              </div>
            </div>

            {/* Pass/Fail Message */}
            <div className={`mt-6 p-4 rounded-lg ${
              attempt.score >= PASSING_SCORE 
                ? 'bg-green-50 border border-green-200' 
                : 'bg-red-50 border border-red-200'
            }`}>
              <div className="flex items-start">
                {attempt.score >= PASSING_SCORE ? (
                  <CheckCircle className="w-5 h-5 text-green-500 mt-0.5" />
                ) : (
                  <AlertTriangle className="w-5 h-5 text-red-500 mt-0.5" />
                )}
                <div className="ml-3">
                  <p className="font-medium mb-1">
                    {attempt.score >= PASSING_SCORE 
                      ? isExamMode
                        ? "Congratulations! You've passed the exam simulation."
                        : `Congratulations! You've passed this domain.`
                      : isExamMode
                        ? 'Keep practicing to improve your exam performance.'
                        : 'Keep practicing to improve your score in this domain.'}
                  </p>
                  <p className="text-sm text-gray-600">
                    {isExamMode
                      ? `You answered ${correctAnswers} out of ${exam.content.questions.length} questions correctly.`
                      : `This domain represents ${exam.metadata?.domains?.weightage[attempt.domain]}% of the certification exam.`}
                  </p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Domain Scores (for exam mode) */}
        {isExamMode && domainScores && (
          <div className="mb-8 space-y-6">
            <h2 className="text-xl font-semibold text-gray-800">Domain Performance</h2>
            <div className="grid grid-cols-1 gap-4">
              {Object.entries(domainScores)
                .filter(([domain]) => domain !== 'overall')
                .map(([domain, scores]) => (
                  <DomainScoreCard
                    key={domain}
                    domain={domain}
                    score={scores.percentage}
                    weight={exam.metadata?.domains?.weightage[domain] || 0}
                    questionsCount={scores.total}
                  />
                ))}
            </div>
          </div>
        )}

        {/* Question Review */}
        <div className="space-y-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Question Review</h2>
          {exam.content.questions.map((question, index) => {
            const userAnswers = attempt.answers[index] || [];
            const isCorrect = JSON.stringify(userAnswers.sort()) === 
                            JSON.stringify(question.answers.sort());

            return (
              <Card key={index} className="bg-white/90 backdrop-blur-md">
                <CardContent className="p-6">
                  <div className="flex items-start justify-between mb-4">
                    <div className="flex-1">
                      <div className="flex items-center mb-2">
                        <span className="text-sm text-gray-500 mr-2">
                          Question {index + 1}
                        </span>
                        {attempt.flaggedQuestions?.includes(index) && (
                          <Flag className="w-4 h-4 text-yellow-500 fill-yellow-500" />
                        )}
                      </div>
                      <p className="text-gray-800 mb-4">{question.question}</p>
                      {isExamMode && question.domain && (
                        <p className="text-sm text-gray-500 mb-2">
                          Domain: {question.domain}
                        </p>
                      )}
                    </div>
                    <div className="ml-4">
                      {isCorrect ? (
                        <CheckCircle className="w-6 h-6 text-green-500" />
                      ) : (
                        <XCircle className="w-6 h-6 text-red-500" />
                      )}
                    </div>
                  </div>

                  <div className="space-y-2">
                    {question.options.map((option, optionIndex) => {
                      const isUserSelected = userAnswers.includes(option);
                      const isCorrectAnswer = question.answers.includes(option);

                      return (
                        <div
                          key={optionIndex}
                          className={`p-3 rounded-lg flex items-center ${
                            isUserSelected
                              ? isCorrectAnswer
                                ? 'bg-green-50 border border-green-200'
                                : 'bg-red-50 border border-red-200'
                              : isCorrectAnswer
                              ? 'bg-blue-50 border border-blue-200'
                              : 'bg-gray-50 border border-gray-200'
                          }`}
                        >
                          <div className="flex-1">{option}</div>
                          {(isUserSelected || isCorrectAnswer) && (
                            <div className="ml-2">
                              {isCorrectAnswer ? (
                                <CheckCircle className="w-4 h-4 text-green-500" />
                              ) : (
                                <XCircle className="w-4 h-4 text-red-500" />
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {!isCorrect && (
                    <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                      <p className="text-sm font-medium text-blue-800 mb-2">
                        Explanation
                      </p>
                      <p className="text-sm text-blue-700">
                        {question.explanation}
                      </p>
                      {question.reference && (
                        <a
                          href={question.reference}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center mt-4 text-sm text-blue-600 hover:text-blue-800"
                        >
                          <ExternalLink className="w-4 h-4 mr-1" />
                          Learn more
                        </a>
                      )}
                    </div>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </div>

        {/* Action Buttons */}
        <div className="mt-8 flex flex-col sm:flex-row gap-4">
          {isExamMode ? (
            <>
              <Button
                onClick={() => navigate(`/test-mode/${exam.metadata.identifier.certificationCode}`)}
                className="flex-1 bg-gradient-to-r from-blue-500 to-purple-600"
              >
                <Trophy className="w-4 h-4 mr-2" />
                Try Another Exam
              </Button>
              
              <Button
                onClick={handlePracticeDomains}
                variant="outline"
                className="flex-1"
              >
                <BookOpen className="w-4 h-4 mr-2" />
                Practice Individual Domains
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => navigate(`/test-mode/${exam.metadata.identifier.certificationCode}`)}
                className="flex-1 bg-gradient-to-r from-blue-500 to-purple-600"
              >
                <BookOpen className="w-4 h-4 mr-2" />
                Practice Another Domain
              </Button>
              
              <Button
                onClick={handleRetake}
                variant="outline"
                className="flex-1"
              >
                <ArrowRight className="w-4 h-4 mr-2" />
                Retake This Domain
              </Button>
            </>
          )}
        </div>

        {/* Learning Tips */}
        {!isExamMode && attempt.score < PASSING_SCORE && (
          <div className="mt-8 p-6 bg-blue-50 rounded-lg">
            <div className="flex items-start space-x-4">
              <Info className="w-6 h-6 text-blue-500 mt-1" />
              <div>
                <h3 className="font-semibold text-blue-900 mb-2">Improvement Tips</h3>
                <ul className="space-y-2 text-blue-800">
                  <li>• Review the questions you got wrong, focusing on understanding the explanations</li>
                  <li>• Take notes on topics that were challenging</li>
                  <li>• Practice this domain again after studying the weak areas</li>
                  <li>• Consider using exam mode to test your knowledge across all domains</li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {/* Exam Mode Tips */}
        {isExamMode && attempt.score < PASSING_SCORE && (
          <div className="mt-8 p-6 bg-blue-50 rounded-lg">
            <div className="flex items-start space-x-4">
              <Info className="w-6 h-6 text-blue-500 mt-1" />
              <div>
                <h3 className="font-semibold text-blue-900 mb-2">Next Steps</h3>
                <ul className="space-y-2 text-blue-800">
                  <li>• Focus on domains where you scored below 70%</li>
                  <li>• Practice individual domains to improve your understanding</li>
                  <li>• Review exam time management - aim to spend time proportional to domain weights</li>
                  <li>• Take another full exam simulation after practicing weak areas</li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamResultScreen;