import React from 'react';
import { motion } from 'framer-motion';
import { 
  Brain, 
  GitBranch,  // Replaced Blocks
  Globe,
  Award,
  Code,
  Bot,
  ArrowRight,
  Github,
  Linkedin,
  Twitter,
  GraduationCap,
  CloudIcon
} from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';

const AboutPage = () => {
    return (
      <div className="pt-32 pb-16">
        {/* Hero Section */}
        <section className="px-4 mb-20">
          <div className="max-w-4xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="flex flex-col md:flex-row items-center gap-12"
            >
              <div className="flex-1">
                <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                  About PracticeQuiz.ai
                </h1>
                <div className="prose prose-lg text-gray-600">
                  <p className="mb-4">
                    Hi, I'm Samir! I'm a Cloud Architect & Engineer based in Sweden, with a passion for building innovative solutions and continuous learning through certifications.
                  </p>
                </div>
              </div>
              <div className="w-64 h-64 rounded-full overflow-hidden shadow-xl">
                <img 
                  src="/samir-pic.jpg"
                  alt="Samir"
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>
          </div>
        </section>

        {/* Origin Story */}
        <section className="px-4 mb-20 bg-gradient-to-r from-blue-50 to-purple-50 py-16">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-gray-800">The Story Behind PracticeQuiz.ai</h2>
            <Card className="bg-white/90 backdrop-blur-md">
              <CardContent className="p-8">
                <div className="prose prose-lg">
                  <p>
                    During my journey of obtaining various cloud certifications, I discovered the power of using AI to enhance my learning process. I started using ChatGPT to create practice questions and test my knowledge in different areas.
                  </p>
                  <p>
                    This approach proved so effective that I wanted to create a more streamlined and accessible platform for others pursuing certifications. That's how PracticeQuiz.ai was born – a tool that combines the power of AI with structured learning approaches.
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </section>

        {/* Mission & Values */}
        <section className="px-4 mb-20">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-12 text-center text-gray-800">Mission</h2>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                {
                  icon: <Brain className="h-8 w-8 text-blue-500" />,
                  title: "Democratize Learning",
                  description: "Make high-quality certification preparation accessible to everyone through AI technology."
                },
                {
                  icon: <Bot className="h-8 w-8 text-purple-500" />,
                  title: "AI-Powered Education",
                  description: "Leverage the latest in AI to create personalized learning experiences."
                },
                {
                  icon: <Award className="h-8 w-8 text-green-500" />,
                  title: "Certification Success",
                  description: "Help professionals achieve their certification goals with targeted practice."
                },
                {
                  icon: <Globe className="h-8 w-8 text-indigo-500" />,
                  title: "Global Impact",
                  description: "Build a community of learners and professionals supporting each other's growth."
                }
              ].map((value, index) => (
                <Card key={index} className="bg-white/90 backdrop-blur-md">
                  <CardContent className="p-6">
                    <div className="flex items-start space-x-4">
                      <div className="mt-1">{value.icon}</div>
                      <div>
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">{value.title}</h3>
                        <p className="text-gray-600">{value.description}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Future Vision */}
        <section className="px-4 mb-20">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-gray-800">Future Vision</h2>
            <Card className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
              <CardContent className="p-8">
                <p className="text-lg mb-6">
                  PracticeQuiz.ai is just getting started. Our vision extends beyond just practice tests – we're building a comprehensive learning platform that will:
                </p>
                <ul className="space-y-4 mb-8">
                  <li className="flex items-center">
                    <GitBranch className="h-5 w-5 mr-3" />
                    <span>Expand to cover more certification paths and learning domains</span>
                  </li>
                  <li className="flex items-center">
                    <Code className="h-5 w-5 mr-3" />
                    <span>Integrate advanced AI features for personalized learning paths</span>
                  </li>
                  <li className="flex items-center">
                    <Globe className="h-5 w-5 mr-3" />
                    <span>Build a community of learners supporting each other</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </section>

        {/* Connect Section */}
        <section className="px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8 text-gray-800">Let's Connect</h2>
            <div className="flex justify-center space-x-6">
              <a href="https://github.com/yourusername" target="_blank" rel="noopener noreferrer" 
                className="text-gray-600 hover:text-blue-600 transition-colors">
                <Github className="h-8 w-8" />
              </a>
              <a href="https://linkedin.com/in/yourusername" target="_blank" rel="noopener noreferrer"
                className="text-gray-600 hover:text-blue-600 transition-colors">
                <Linkedin className="h-8 w-8" />
              </a>
              <a href="https://twitter.com/yourusername" target="_blank" rel="noopener noreferrer"
                className="text-gray-600 hover:text-blue-600 transition-colors">
                <Twitter className="h-8 w-8" />
              </a>
            </div>
          </div>
        </section>
      </div>
    );
};

export default AboutPage;