// examMode.js

/**
 * Calculates scores for each domain based on user answers
 * @param {Object} answers - User's answers keyed by question index
 * @param {Array} questions - Array of questions with domain information
 * @returns {Object} - Domain scores with percentages and statistics
 */
export const calculateDomainScores = (answers, questions) => {
    if (!Array.isArray(questions) || questions.length === 0) {
      console.error('Invalid questions array');
      return {
        overall: {
          percentage: 0,
          correct: 0,
          total: 0
        }
      };
    }
  
    try {
      // Group questions by domain
      const domainGroups = questions.reduce((acc, question, index) => {
        const domain = question.domain || 'Unknown Domain';
        if (!acc[domain]) {
          acc[domain] = {
            questions: [],
            userAnswers: [],
            correct: 0,
            total: 0
          };
        }
        
        acc[domain].questions.push(question);
        acc[domain].userAnswers.push(answers[index] || []);
        acc[domain].total++;
  
        // Check if answer is correct
        const userAnswer = answers[index] || [];
        const correctAnswers = question.answers || [];
        const isCorrect = JSON.stringify([...userAnswer].sort()) === 
                         JSON.stringify([...correctAnswers].sort());
        
        if (isCorrect) {
          acc[domain].correct++;
        }
  
        return acc;
      }, {});
  
      // Calculate percentages for each domain
      const finalScores = {};
      let totalCorrect = 0;
      let totalQuestions = 0;
  
      Object.entries(domainGroups).forEach(([domain, data]) => {
        const percentage = data.total > 0 
          ? Number(((data.correct / data.total) * 100).toFixed(1))
          : 0;
  
        finalScores[domain] = {
          percentage,
          correct: data.correct,
          total: data.total,
          questions: data.questions.map((q, i) => ({
            ...q,
            userAnswer: data.userAnswers[i],
            isCorrect: JSON.stringify([...data.userAnswers[i]].sort()) === 
                      JSON.stringify([...q.answers].sort())
          }))
        };
  
        totalCorrect += data.correct;
        totalQuestions += data.total;
      });
  
      // Add overall score
      finalScores.overall = {
        percentage: Number(((totalCorrect / totalQuestions) * 100).toFixed(1)),
        correct: totalCorrect,
        total: totalQuestions
      };
  
      console.log('Domain scores calculated:', finalScores);
      return finalScores;
    } catch (error) {
      console.error('Error calculating domain scores:', error);
      return {
        overall: {
          percentage: 0,
          correct: 0,
          total: questions.length || 0
        }
      };
    }
  };
  
  /**
   * Prepares questions for exam mode with balanced domain distribution
   */
  export const prepareExamModeQuestions = (exam) => {
    if (!exam?.metadata?.domains?.sub) {
      console.error('Missing required exam metadata');
      return [];
    }
  
    try {
      const MAX_EXAM_QUESTIONS = 60;
      const cachedQuestions = JSON.parse(localStorage.getItem('cachedQuestions') || '{}');
      const certCode = exam.metadata?.identifier?.certificationCode;
      const domains = exam.metadata.domains.sub;
      const weightage = exam.metadata.domains.weightage || {};
      
      if (!certCode) {
        console.error('Missing certification code');
        return [];
      }
  
      // Calculate questions per domain based on weightage
      const domainQuestions = {};
      let remainingQuestions = MAX_EXAM_QUESTIONS;
      let totalWeight = Object.values(weightage).reduce((sum, w) => sum + w, 0);
  
      // First pass: Calculate initial distribution based on weights
      domains.forEach(domain => {
        const weight = weightage[domain] || 0;
        const questionCount = Math.round((weight / totalWeight) * MAX_EXAM_QUESTIONS);
        domainQuestions[domain] = questionCount;
        remainingQuestions -= questionCount;
      });
  
      // Second pass: Distribute any remaining questions
      while (remainingQuestions > 0) {
        for (const domain of domains) {
          if (remainingQuestions > 0) {
            domainQuestions[domain]++;
            remainingQuestions--;
          }
        }
      }
  
      // Get questions from each domain
      const selectedQuestions = [];
      domains.forEach(domain => {
        const cacheKey = `${certCode}_${domain}`;
        const availableQuestions = cachedQuestions[cacheKey] || [];
        
        if (availableQuestions.length > 0) {
          // Randomly select required number of questions from this domain
          const shuffled = [...availableQuestions]
            .sort(() => Math.random() - 0.5)
            .slice(0, domainQuestions[domain])
            .map(q => ({
              ...q,
              domain // Add domain information to each question
            }));
            
          selectedQuestions.push(...shuffled);
        }
      });
  
      // Final shuffle of all selected questions
      const shuffledQuestions = selectedQuestions
        .sort(() => Math.random() - 0.5)
        .slice(0, MAX_EXAM_QUESTIONS);
  
      return shuffledQuestions;
    } catch (error) {
      console.error('Error preparing exam questions:', error);
      return [];
    }
  };
  
  /**
   * Checks if all domains have been generated for an exam
   */
  export const checkAllDomainsGenerated = (exam) => {
    if (!exam?.metadata?.domains?.sub) {
      return false;
    }
  
    try {
      const cachedQuestions = JSON.parse(localStorage.getItem('cachedQuestions') || '{}');
      const certCode = exam.metadata?.identifier?.certificationCode;
      
      if (!certCode) return false;
  
      return exam.metadata.domains.sub.every(domain => {
        const cacheKey = `${certCode}_${domain}`;
        return (
          cachedQuestions[cacheKey] && 
          Array.isArray(cachedQuestions[cacheKey]) && 
          cachedQuestions[cacheKey].length > 0
        );
      });
    } catch (error) {
      console.error('Error checking domain generation:', error);
      return false;
    }
  };