import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';

const TOTAL_DURATION = 20000; // 20 seconds total
const ANALYSIS_DURATION = 3000; // 3 seconds for analysis
const DOMAINS_START_PERCENT = 40;
const DOMAINS_END_PERCENT = 90;

const LoadingProgress = ({ 
  isLoading,
  metadata,
  onComplete,
  userPrompt,
  requestStatus,
  existingExamData,
  onMetadataReceived // New prop for background test generation
}) => {
  const [progress, setProgress] = useState(0);
  const [startTime] = useState(Date.now());
  const [message, setMessage] = useState('');
  const [phase, setPhase] = useState('analyzing');
  const [currentDomainIndex, setCurrentDomainIndex] = useState(0);
  const [isCompleting, setIsCompleting] = useState(false);
  const [hasEmittedMetadata, setHasEmittedMetadata] = useState(false);

  // Emit metadata when received for background test generation
  useEffect(() => {
    if (metadata && !hasEmittedMetadata && onMetadataReceived) {
      onMetadataReceived(metadata);
      setHasEmittedMetadata(true);
    }
  }, [metadata, hasEmittedMetadata, onMetadataReceived]);

  const calculateProgress = useCallback(() => {
    const currentTime = Date.now();
    const elapsed = currentTime - startTime;
    
    // Analysis phase (0-30%)
    if (elapsed <= ANALYSIS_DURATION) {
      return (elapsed / ANALYSIS_DURATION) * 30;
    }
    
    // Processing phase (30-100%)
    const remainingTime = elapsed - ANALYSIS_DURATION;
    const remainingProgress = (remainingTime / (TOTAL_DURATION - ANALYSIS_DURATION)) * 70;
    return Math.min(100, 30 + remainingProgress);
  }, [startTime]);

  const getNextMessage = useCallback(() => {
    const currentProgress = progress;
    
    // Initial analysis phase (0-30%)
    if (currentProgress < 30) {
      return `Analyzing "${userPrompt}"... 🔍`;
    }
    
    // If we have metadata, show more detailed progress
    if (metadata) {
      const certName = metadata?.identifier?.displayName;
      const domains = metadata?.domains?.weightage || {};
      const domainNames = Object.keys(domains);

      if (currentProgress < 40) {
        return `Found ${certName} certification! 📚`;
      }

      // Domain processing phase (40-90%)
      if (currentProgress >= DOMAINS_START_PERCENT && currentProgress < DOMAINS_END_PERCENT) {
        if (domainNames.length > 0) {
          const domainProgress = currentProgress - DOMAINS_START_PERCENT;
          const progressPerDomain = (DOMAINS_END_PERCENT - DOMAINS_START_PERCENT) / domainNames.length;
          const domainIndex = Math.floor(domainProgress / progressPerDomain);
          
          if (domainIndex !== currentDomainIndex && domainIndex < domainNames.length) {
            setCurrentDomainIndex(domainIndex);
          }
          
          const currentDomain = domainNames[Math.min(domainIndex, domainNames.length - 1)];
          const domainWeight = domains[currentDomain];
          
          const domainEmojis = ['📚', '⚡', '🤓', '💡', '🎯', '🔧'];
          const emojiIndex = domainIndex % domainEmojis.length;
          
          return `Processing ${currentDomain} domain (${domainWeight}%) ${domainEmojis[emojiIndex]}`;
        }
      }

      // Finalizing phase (90-100%)
      if (currentProgress < 95) {
        return 'Finalizing exam structure... 🔄';
      }
      
      return 'Ready to start practice! 🚀';
    }
    
    // Generic message if metadata isn't available yet
    return 'Searching for certification requirements... 🔎';
  }, [progress, metadata, userPrompt, currentDomainIndex]);

  // Progress update effect
  useEffect(() => {
    let progressInterval;

    if (isLoading && !isCompleting) {
      progressInterval = setInterval(() => {
        const newProgress = calculateProgress();
        setProgress(newProgress);
        
        if (newProgress >= 30) {
          setPhase('processing');
        }
        
        // Only complete when we reach 100%
        if (newProgress >= 100) {
          clearInterval(progressInterval);
          setProgress(100);
          setIsCompleting(true);
          
          // Wait for a moment at 100% before completing
          setTimeout(() => {
            if (onComplete) {
              onComplete();
            }
          }, 1000);
        }
      }, 50);
    } else if (!isLoading) {
      setProgress(0);
      setMessage('');
      setPhase('analyzing');
      setCurrentDomainIndex(0);
      setIsCompleting(false);
      setHasEmittedMetadata(false);
    }

    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [isLoading, calculateProgress, onComplete, isCompleting]);

  // Message update effect
  useEffect(() => {
    const newMessage = getNextMessage();
    if (newMessage !== message) {
      setMessage(newMessage);
    }
  }, [getNextMessage, message]);

  if (!isLoading) return null;

  return (
    <div className="mt-6 space-y-6">
      <div className="flex items-center gap-2 mb-2">
        <Loader2 className="w-4 h-4 text-blue-500 animate-spin" />
        <span className="text-sm text-gray-600">
          {message}
        </span>
      </div>
      
      <div className="relative h-2 bg-gray-100 rounded-full overflow-hidden">
        <motion.div
          className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-purple-500"
          style={{ width: `${Math.round(progress)}%` }}
          initial={{ width: "0%" }}
          animate={{ width: `${Math.round(progress)}%` }}
          transition={{ duration: 0.3, ease: "linear" }}
        />
      </div>
    </div>
  );
};

export default LoadingProgress;