import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { UserContext } from '../context/UserContext';
import {
  Flag,
  Timer,
  ChevronLeft,
  ChevronRight,
  AlertCircle,
  ArrowLeft,
  CheckCircle,
  XCircle,
  ExternalLink,
  Info
} from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';
import QuestionFeedback from './QuestionFeedback';
import { calculateDomainScores } from '../../lib/examMode';
import ExamTimer from './ExamTimer';


const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const TimerDisplay = ({ startTime }) => {
  const [time, setTime] = useState('00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const diff = Math.floor((now - startTime) / 1000);
      setTime(formatTime(diff));
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <div className="flex items-center space-x-2 text-gray-600">
      <Timer className="w-5 h-5" />
      <span className="font-mono">{time}</span>
    </div>
  );
};

const QuestionMap = ({ 
  questions, 
  currentIndex, 
  userAnswers, 
  flaggedQuestions,
  isExamMode,
  onQuestionSelect 
}) => {
  return (
    <div className="bg-white p-6 rounded-lg h-full">
      <h3 className="text-lg font-semibold mb-4">Questions</h3>
      <div className="grid grid-cols-5 gap-2">
        {questions.map((_, index) => {
          const isAnswered = userAnswers[index]?.length > 0;
          const isFlagged = flaggedQuestions.has(index);
          const isCurrent = currentIndex === index;

          return (
            <button
              key={index}
              onClick={() => onQuestionSelect(index)}
              className={`
                w-10 h-10 rounded-lg flex items-center justify-center relative
                ${isCurrent ? 'ring-2 ring-purple-500' : ''}
                ${isAnswered ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}
                hover:opacity-80 transition-all
              `}
            >
              {index + 1}
              {isFlagged && (
                <Flag className="absolute -top-1 -right-1 w-4 h-4 text-yellow-500 fill-yellow-500" />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const QuestionOption = ({ 
  option, 
  isSelected, 
  isCorrectAnswer,
  showFeedback,
  answerType,
  onChange 
}) => (
  <label className="block">
    <div className="p-4 cursor-pointer">
      <div className="flex items-center">
        <input
          type={answerType}
          checked={isSelected}
          onChange={() => onChange(option)}
          className="sr-only"
        />
        <div className={`
          w-6 h-6 ${answerType === 'checkbox' ? 'rounded' : 'rounded-full'}
          border border-gray-300 flex items-center justify-center
          ${isSelected ? 'bg-blue-500 border-blue-500' : 'bg-white'}
        `}>
          {isSelected && (
            <div className="text-white text-sm">✓</div>
          )}
        </div>
        <span className="ml-3 flex-grow">{option}</span>
        
        {showFeedback && (
          <div className="ml-4">
            {isCorrectAnswer ? (
              <CheckCircle className="w-5 h-5 text-green-500" />
            ) : isSelected ? (
              <XCircle className="w-5 h-5 text-red-500" />
            ) : null}
          </div>
        )}
      </div>
    </div>
  </label>
);

const PracticeTestPlayground = () => {
  const navigate = useNavigate();
  const { user, updateExamScore } = useContext(UserContext);
  const [exam, setExam] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [flaggedQuestions, setFlaggedQuestions] = useState(new Set());
  const [showFeedback, setShowFeedback] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [error, setError] = useState('');
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // New state for exam mode
  const [isExamMode, setIsExamMode] = useState(false);
  const [showExamSummary, setShowExamSummary] = useState(false);

  useEffect(() => {
    const examData = localStorage.getItem('currentExam');
    if (!examData) {
      navigate('/');
      return;
    }

    try {
      const parsedExam = JSON.parse(examData);
      
      if (!parsedExam?.exam?.content?.questions?.length || 
          !parsedExam?.exam?.content?.generated) {
        navigate(`/test-mode/${exam.metadata.identifier.certificationCode}`);
        return;
      }

      setExam(parsedExam.exam);
      setStartTime(parsedExam.startTime || Date.now());
      setIsExamMode(parsedExam.exam?.content?.mode === 'exam');
    } catch (error) {
      console.error('Error loading exam:', error);
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    setError('');
  }, [currentQuestionIndex]);

  const handleAnswer = (answer) => {
    setUserAnswers(prev => {
      const currentAnswers = prev[currentQuestionIndex] || [];
      const questionAnswersRequired = exam.content.questions[currentQuestionIndex].answers.length;
      
      let newAnswers;
      if (questionAnswersRequired > 1) {
        if (currentAnswers.includes(answer)) {
          newAnswers = currentAnswers.filter(a => a !== answer);
        } else if (currentAnswers.length < questionAnswersRequired) {
          newAnswers = [...currentAnswers, answer];
        } else {
          setError(`This question requires exactly ${questionAnswersRequired} answers.`);
          return prev;
        }
      } else {
        newAnswers = [answer];
      }

      // Only show feedback immediately if not in exam mode
      if (!isExamMode) {
        const correctAnswers = exam.content.questions[currentQuestionIndex].answers;
        const isCorrect = JSON.stringify(newAnswers.sort()) === JSON.stringify(correctAnswers.sort());
        setShowFeedback(prev => ({
          ...prev,
          [currentQuestionIndex]: isCorrect
        }));
      }

      setError('');
      return {
        ...prev,
        [currentQuestionIndex]: newAnswers
      };
    });
  };

  const toggleFlagQuestion = () => {
    setFlaggedQuestions(prev => {
      const newFlags = new Set(prev);
      if (newFlags.has(currentQuestionIndex)) {
        newFlags.delete(currentQuestionIndex);
      } else {
        newFlags.add(currentQuestionIndex);
      }
      return newFlags;
    });
  };

  const calculateScore = () => {
    if (!exam?.content?.questions) return 0;
    let score = 0;
    exam.content.questions.forEach((question, index) => {
      const userAnswer = userAnswers[index] || [];
      const correctAnswers = question.answers;
      if (JSON.stringify(userAnswer.sort()) === JSON.stringify(correctAnswers.sort())) {
        score++;
      }
    });
    return (score / exam.content.questions.length) * 100;
  };

  const handleFinish = async () => {
    try {
      setIsSubmitting(true);
      setError('');
      
      const endTime = Date.now();
      const timeTaken = Math.floor((endTime - startTime) / 1000);
      const score = calculateScore();
  
      // Calculate and format domain scores for exam mode
      const examModeData = isExamMode ? {
        domainScores: Object.entries(calculateDomainScores(userAnswers, exam.content.questions))
          .reduce((acc, [domain, data]) => ({
            ...acc,
            [domain]: {
              percentage: data.percentage,
              correct: data.correct,
              total: data.total
            }
          }), {}),
        isExamMode: true
      } : {};
  
      // Create attempt without duplicating questions
      const completedAttempt = {
        id: `${Date.now()}`,
        score,
        answers: userAnswers,
        completed: true,
        timeTaken: formatTime(timeTaken),
        date: new Date().toISOString(),
        flaggedQuestions: Array.from(flaggedQuestions),
        domain: isExamMode ? 'exam' : exam.content.domain,
        certificationCode: exam.metadata?.identifier?.certificationCode,
        ...examModeData
      };
  
      const examData = {
        ...exam,
        metadata: exam.metadata,
        name: exam.metadata?.identifier?.displayName,
        certificationCode: exam.metadata?.identifier?.certificationCode
      };
  
      await updateExamScore(examData.name, completedAttempt, examData);
      
      // Save full details for review including questions
      const reviewData = {
        exam: {
          ...examData,
          content: {
            ...exam.content,
            questions: exam.content.questions,
            isExamMode
          }
        },
        attempt: {
          ...completedAttempt,
          domainScores: isExamMode ? calculateDomainScores(userAnswers, exam.content.questions) : null,
          questions: exam.content.questions
        }
      };
      
      localStorage.setItem('reviewExam', JSON.stringify(reviewData));
      localStorage.removeItem('currentExam');
  
      navigate(`/exam-result/${encodeURIComponent(examData.name)}/${completedAttempt.id}`);
  
    } catch (error) {
      console.error('Error finishing exam:', error);
      setError('Failed to save exam results. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!exam) return null;

  const currentQuestion = exam.content.questions[currentQuestionIndex];
  const totalQuestions = exam.content.questions.length;
  const isLastQuestion = currentQuestionIndex + 1 === totalQuestions;
  const hasSelectedAnswers = (userAnswers[currentQuestionIndex] || []).length > 0;
  const hasCorrectAnswerCount = (userAnswers[currentQuestionIndex] || []).length === currentQuestion.answers.length;
  const showQuestionFeedback = !isExamMode && hasCorrectAnswerCount;

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-100 via-white to-purple-100">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white/90 backdrop-blur-md shadow-sm z-50 h-16">
      <div className="relative h-full">
        <div className="absolute left-4 h-full flex items-center">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setShowExitDialog(true)}
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Exit
          </Button>
        </div>

        <div className="max-w-screen-2xl mx-auto h-full flex items-center">
          <div className="w-full max-w-4xl mx-auto flex justify-center">
            <div className="w-full pl-12 sm:pl-24">
              <h1 className="text-xl font-semibold text-gray-800">
                {exam.metadata?.identifier?.displayName}
              </h1>
              <p className="text-sm text-gray-600">
                {isExamMode ? 'Exam Mode' : `Domain: ${exam.content.domain}`}
              </p>
            </div>
          </div>
        </div>

        <div className="absolute right-4 top-0 h-full flex items-center">
          {isExamMode ? (
            <ExamTimer 
              startTime={startTime} 
              onTimeUp={handleFinish}
            />
          ) : (
            <TimerDisplay startTime={startTime} />
          )}
          
          <Button
            variant="ghost"
            size="sm"
            onClick={toggleFlagQuestion}
            className="ml-6 text-gray-600 hover:text-gray-900"
          >
            <Flag className={`w-5 h-5 ${
              flaggedQuestions.has(currentQuestionIndex) ? 'fill-yellow-500 text-yellow-500' : ''
            }`} />
          </Button>
        </div>
      </div>
    </header>
  
      {/* Main Content */}
      <div className="flex flex-1 pt-16">
        <div className="flex-1 p-6">
          <div className="max-w-4xl mx-auto">
            <motion.div
              key={currentQuestionIndex}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-lg p-6 shadow-sm"
            >
              {/* Question Header */}
              <div className="flex justify-between items-start mb-6">
                <div>
                  <h2 className="text-xl font-semibold">
                    Question {currentQuestionIndex + 1} of {totalQuestions}
                  </h2>
                  {isExamMode && currentQuestion.domain && (
                    <p className="text-sm text-gray-500 mt-1">
                      Domain: {currentQuestion.domain}
                    </p>
                  )}
                </div>
                {!isExamMode && (
                  <QuestionFeedback
                    questionId={currentQuestion.id}
                    examId={exam.metadata?.identifier?.certificationCode}
                    onError={setError}
                  />
                )}
              </div>
  
              {/* Question Content */}
              <div className="mb-6">
                <p className="text-lg text-gray-800">{currentQuestion.question}</p>
              </div>
  
              {/* Instructions */}
              <div className="mb-6">
                <p className="text-sm text-gray-600">
                  Select {currentQuestion.answers.length} answer{currentQuestion.answers.length > 1 ? 's' : ''}.
                </p>
              </div>
  
              {/* Answer Options */}
              <div className="space-y-2">
                {currentQuestion.options.map((option, index) => (
                  <QuestionOption
                    key={index}
                    option={option}
                    isSelected={(userAnswers[currentQuestionIndex] || []).includes(option)}
                    isCorrectAnswer={currentQuestion.answers.includes(option)}
                    showFeedback={showQuestionFeedback}
                    answerType={currentQuestion.answers.length > 1 ? 'checkbox' : 'radio'}
                    onChange={handleAnswer}
                  />
                ))}
              </div>
  
              {/* Error Message */}
              {error && (
                <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-lg flex items-center">
                  <AlertCircle className="w-5 h-5 mr-2" />
                  {error}
                </div>
              )}
  
              {/* Answer Feedback */}
              {showQuestionFeedback && (
                <div className="mt-6">
                  <div className={`p-6 rounded-lg ${
                    showFeedback[currentQuestionIndex] 
                      ? 'bg-green-50 border border-green-200' 
                      : 'bg-red-50 border border-red-200'
                  }`}>
                    <div className="flex items-start space-x-3">
                      {showFeedback[currentQuestionIndex] ? (
                        <CheckCircle className="w-6 h-6 text-green-500 mt-0.5" />
                      ) : (
                        <XCircle className="w-6 h-6 text-red-500 mt-0.5" />
                      )}
                      <div className="flex-1">
                        <p className="font-semibold mb-2">
                          {showFeedback[currentQuestionIndex] ? 'Correct!' : 'Incorrect'}
                        </p>
                        <p className="text-gray-700">{currentQuestion.explanation}</p>
                        {currentQuestion.reference && (
                          <a
                            href={currentQuestion.reference}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center mt-4 text-blue-600 hover:text-blue-800"
                          >
                            <ExternalLink className="w-4 h-4 mr-1" />
                            Learn more
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isExamMode && (
                <div className="mt-6 p-4 bg-blue-50 rounded-lg">
                  <div className="flex items-center text-blue-700">
                    <Info className="w-5 h-5 mr-2" />
                    <p className="text-sm">
                      In exam mode, answers will be revealed after you submit the entire test.
                    </p>
                  </div>
                </div>
              )}
  
              {/* Navigation */}
              <div className="flex justify-between mt-8">
                <Button
                  onClick={() => setCurrentQuestionIndex(prev => Math.max(0, prev - 1))}
                  variant="outline"
                  disabled={currentQuestionIndex === 0}
                  className="flex items-center"
                >
                  <ChevronLeft className="w-5 h-5 mr-1" />
                  Previous
                </Button>
  
                {isLastQuestion ? (
                  <Button
                    onClick={handleFinish}
                    className="bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                    disabled={!hasSelectedAnswers || isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="flex items-center">
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2" />
                        Submitting...
                      </div>
                    ) : isExamMode ? (
                      'Submit Exam'
                    ) : (
                      'Finish Test'
                    )}
                  </Button>
                ) : (
                  <Button
                    onClick={() => setCurrentQuestionIndex(prev => prev + 1)}
                    className="flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                    disabled={!hasSelectedAnswers}
                  >
                    Next
                    <ChevronRight className="w-5 h-5 ml-1" />
                  </Button>
                )}
              </div>
            </motion.div>
          </div>
        </div>
  
        {/* Question Map Sidebar */}
        <div className="w-80 p-6 border-l border-gray-200 bg-white">
          <QuestionMap
            questions={exam.content.questions}
            currentIndex={currentQuestionIndex}
            userAnswers={userAnswers}
            flaggedQuestions={flaggedQuestions}
            isExamMode={isExamMode}
            onQuestionSelect={setCurrentQuestionIndex}
          />
        </div>
      </div>
  
      {/* Exit Dialog */}
      <AlertDialog open={showExitDialog} onOpenChange={setShowExitDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Exit {isExamMode ? 'Exam' : 'Practice Test'}?</AlertDialogTitle>
            <AlertDialogDescription>
              Your progress will be lost. Are you sure you want to exit?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setShowExitDialog(false)}>
              Continue {isExamMode ? 'Exam' : 'Test'}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                localStorage.removeItem('currentExam');
                navigate(`/test-mode/${exam.metadata.identifier.certificationCode}`);
              }}
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              Exit {isExamMode ? 'Exam' : 'Test'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
  
      {/* Progress Toast */}
      <AnimatePresence>
        {isSubmitting && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4"
          >
            <div className="flex items-center space-x-3">
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500" />
              <span className="text-gray-600">Saving your progress...</span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PracticeTestPlayground;