import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Send, LogIn, AlertCircle, CreditCard, FolderOpen } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { motion, AnimatePresence } from 'framer-motion';
import LoadingProgress from '../exam/LoadingProgress'; 
import { API_ENDPOINT } from '../../config/env';

const LoginPrompt = ({ onLogin }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
        <CardContent className="p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Login Required</h2>
          <p className="text-gray-600 mb-6">Please log in to generate practice exams and start learning.</p>
          <Button
            onClick={onLogin}
            className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
          >
            <LogIn className="mr-2 h-5 w-5" />
            Log In
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const ErrorModal = ({ message, onClose, isExamLimitError, onUpgrade, onManageExams }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl w-full max-w-md">
        <CardContent className="p-6">
          <div className="flex items-center mb-4">
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
            <h2 className="text-2xl font-bold text-gray-800">Error</h2>
          </div>
          <p className="text-gray-600 mb-6">{message}</p>
          {isExamLimitError ? (
            <div className="space-y-4">
              <Button
                onClick={onUpgrade}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full"
              >
                <CreditCard className="mr-2 h-5 w-5" />
                Upgrade Plan
              </Button>
              <Button
                onClick={onManageExams}
                className="w-full bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white font-bold py-3 px-6 rounded-full"
              >
                <FolderOpen className="mr-2 h-5 w-5" />
                Manage Existing Exams
              </Button>
            </div>
          ) : (
            <Button
              onClick={onClose}
              className="w-full bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white font-bold py-3 px-6 rounded-full"
            >
              Close
            </Button>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

const placeholderPhrases = [
  "Azure Administrator (AZ-104)",
  "AWS Solutions Architect Associate",
  "CompTIA Security+",
  "Google Cloud Engineer",
  "Cisco CCNA",
  "Microsoft 365 MS-900"
];

const useAnimatedPlaceholder = (phrases, typingSpeed = 100, deletingSpeed = 50, pauseDuration = 2000) => {
  const [placeholderText, setPlaceholderText] = useState('');
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let timeout;

    const animate = () => {
      const currentPhrase = phrases[currentPhraseIndex];

      if (isPaused) {
        timeout = setTimeout(() => {
          setIsPaused(false);
          setIsDeleting(true);
        }, pauseDuration);
        return;
      }

      if (isDeleting) {
        if (placeholderText === '') {
          setIsDeleting(false);
          setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
        } else {
          setPlaceholderText(prev => prev.slice(0, -1));
        }
        timeout = setTimeout(animate, deletingSpeed);
      } else {
        if (placeholderText === currentPhrase) {
          setIsPaused(true);
        } else {
          setPlaceholderText(currentPhrase.slice(0, placeholderText.length + 1));
        }
        timeout = setTimeout(animate, typingSpeed);
      }
    };

    timeout = setTimeout(animate, typingSpeed);
    return () => clearTimeout(timeout);
  }, [placeholderText, currentPhraseIndex, isDeleting, isPaused, phrases, typingSpeed, deletingSpeed, pauseDuration]);

  return placeholderText;
};

const findMatchingUserExam = (metadata, userExams) => {
  if (!metadata || !userExams) return null;

  return userExams.find(savedExam => {
    if (metadata.identifier?.certificationCode && 
        savedExam.metadata?.identifier?.certificationCode) {
      return metadata.identifier.certificationCode.toLowerCase() === 
             savedExam.metadata.identifier.certificationCode.toLowerCase();
    }
    
    if (metadata.identifier?.displayName && 
        savedExam.metadata?.identifier?.displayName) {
      return metadata.identifier.displayName.toLowerCase() === 
             savedExam.metadata.identifier.displayName.toLowerCase();
    }
    
    if (metadata.identifier?.displayName && savedExam.name) {
      return metadata.identifier.displayName.toLowerCase() === 
             savedExam.name.toLowerCase();
    }
    
    return false;
  });
};

const LLMInteraction = () => {
  const [input, setInput] = useState(() => {
    const savedPrompt = localStorage.getItem('savedPrompt');
    return savedPrompt || '';
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isExamLimitError, setIsExamLimitError] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [requestStatus, setRequestStatus] = useState('pending');
  
  const { user, saveGeneratedTest, saveCertificationMetadata } = useContext(UserContext);
  const navigate = useNavigate();
  const animatedPlaceholder = useAnimatedPlaceholder(placeholderPhrases);

  const startBackgroundGeneration = async (metadata) => {
    try {
      const isProUser = user?.plan === 'pro';
      const domains = metadata?.domains?.sub || [];
      const domainsToGenerate = isProUser ? domains : [domains[0]];
      
      // Instead of triggering generation, just mark domains as "generating"
      const generatingStatus = JSON.parse(localStorage.getItem('generatingDomains') || '{}');
      
      domainsToGenerate.forEach(domain => {
        generatingStatus[`${metadata.identifier.certificationCode}_${domain}`] = true;
      });
      
      localStorage.setItem('generatingDomains', JSON.stringify(generatingStatus));
  
    } catch (error) {
      console.error('Error setting up background generation:', error);
    }
  };

  const analyzeTopic = async (topic) => {
    try {
      console.log('Starting analyzeTopic with:', { topic });
      
      setIsLoading(true);
      setError(null);
      setRequestStatus('pending');
      setMetadata(null);
  
      const auth = getAuth();
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
  
      const startTime = Date.now();
      const ANALYSIS_DURATION = 3000;
      const TOTAL_DURATION = 20000;
  
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_ENDPOINT}/analyze-topic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken
        },
        body: JSON.stringify({ prompt: topic })
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to analyze topic');
      }
  
      const data = await response.json();
      
      if (!data.metadata || !data.metadata.domains) {
        throw new Error('Invalid metadata received from server');
      }
  
      setMetadata(data.metadata);
      setRequestStatus('processing');
      
      // Save certification metadata to user context immediately after receiving it
      await saveCertificationMetadata(data.metadata);
  
      // Calculate how long the API call took
      const apiCallDuration = Date.now() - startTime;
      const remainingAnalysisTime = Math.max(0, ANALYSIS_DURATION - apiCallDuration);
      
      // Wait for the analysis phase to complete
      await new Promise(resolve => setTimeout(resolve, remainingAnalysisTime));
  
      const examForStorage = {
        exam: {
          name: data.metadata.identifier.displayName,
          metadata: data.metadata,
          content: {
            questions: [],
            status: 'pending',
            totalQuestions: 30,
            completedBatches: 0,
            totalBatches: 1,
            userAccess: data.userAccess
          }
        },
        startTime: Date.now()
      };
  
      localStorage.setItem('currentExam', JSON.stringify(examForStorage));
      
      // Wait for the full duration
      const totalElapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, TOTAL_DURATION - totalElapsedTime);
      await new Promise(resolve => setTimeout(resolve, remainingTime));
      
      setRequestStatus('complete');
  
    } catch (error) {
      console.error('Error in analyzeTopic:', error);
      setError(error.message || 'Failed to analyze topic. Please try again.');
      setRequestStatus('error');
      setMetadata(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    if (!user) {
      localStorage.setItem('savedPrompt', input.trim());
      setShowLoginPrompt(true);
      return;
    }

    await analyzeTopic(input.trim());
  };

  const handleLogin = () => {
    navigate('/login', { 
      state: { previousPath: '/generate' }
    });
  };

  const handleCloseError = () => {
    setError(null);
    setIsExamLimitError(false);
  };

  const handleUpgrade = () => {
    navigate('/pricing');
  };

  const handleManageExams = () => {
    navigate('/account');
  };

  useEffect(() => {
    const savedPrompt = localStorage.getItem('savedPrompt');
    if (savedPrompt && user) {
      localStorage.removeItem('savedPrompt');
      setInput(savedPrompt);
      analyzeTopic(savedPrompt);
    }
  }, [user]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center">
      <div className="w-full max-w-4xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            AI Generated Practice Tests
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Master your certification exams with domain-focused practice
          </p>
          
          <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl">
            <CardContent className="p-6">
              <form onSubmit={handleSubmit} className="mb-4">
                <div className="flex items-center space-x-2 bg-white rounded-full shadow-inner p-2">
                  <Input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={input ? "" : animatedPlaceholder}
                    className="flex-grow border-none shadow-none focus:ring-0"
                    disabled={isLoading}
                  />
                  <Button 
                    type="submit" 
                    className="bg-transparent hover:bg-gray-100 rounded-full p-2" 
                    disabled={isLoading}
                  >
                    <Send className="h-5 w-5 text-gray-500" />
                  </Button>
                </div>
              </form>

              {isLoading && (
            <LoadingProgress 
              isLoading={isLoading}
              metadata={metadata}
              requestStatus={requestStatus}
              existingExamData={findMatchingUserExam(metadata, user?.savedExams)}
              userPrompt={input}
              onMetadataReceived={startBackgroundGeneration}
              onComplete={() => {
                setIsLoading(false);
                // Use metadata from state instead of undefined exam variable
                if (metadata?.identifier?.certificationCode) {
                  navigate(`/test-mode/${metadata.identifier.certificationCode}`);
                } else {
                  navigate('/test-mode');
                }
              }}
            />
          )}

              {!isLoading && !user && (
                <div className="mt-4 p-4 bg-blue-50 rounded-lg flex items-start space-x-2">
                  <CreditCard className="w-5 h-5 text-blue-500 mt-0.5" />
                  <p className="text-sm text-blue-700">
                    Sign in to access domain-specific practice tests and track your progress
                  </p>
                </div>
              )}
            </CardContent>
          </Card>
        </motion.div>
      </div>

      <AnimatePresence>
        {showLoginPrompt && <LoginPrompt onLogin={handleLogin} />}
        {error && (
          <ErrorModal 
            message={error} 
            onClose={handleCloseError}
            isExamLimitError={isExamLimitError}
            onUpgrade={handleUpgrade}
            onManageExams={handleManageExams}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default LLMInteraction;