import React, { createContext, useState, useEffect } from 'react';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  signOut, 
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  deleteDoc
} from 'firebase/firestore';
import { auth, db } from '../../firebaseConfig';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const data = userDoc.data();
        
        // Get user's saved exams with domains
        const savedExams = await Promise.all((data.savedExams || []).map(async (exam) => {
          if (!exam.normalizedId) return exam;
          
          try {
            const metadataRef = doc(db, 'questionbank', exam.normalizedId);
            const metadataDoc = await getDoc(metadataRef);
            
            if (metadataDoc.exists()) {
              return {
                ...exam,
                metadata: metadataDoc.data().metadata
              };
            }
            return exam;
          } catch (error) {
            console.error(`Error fetching metadata for exam ${exam.normalizedId}:`, error);
            return exam;
          }
        }));

        const userData = {
          id: userId,
          email: data.email || auth.currentUser?.email,
          name: data.name || auth.currentUser?.displayName,
          emailVerified: data.emailVerified || auth.currentUser?.emailVerified,
          plan: data.plan || 'free',
          savedExams: savedExams,
          planStartDate: data.planStartDate || null,
          planEndDate: data.planEndDate || null,
          createdAt: data.createdAt || new Date().toISOString()
        };

        setUser(userData);
        return userData;
      } else {
        // Create new user document if it doesn't exist
        const newUser = {
          id: userId,
          email: auth.currentUser?.email || '',
          name: auth.currentUser?.displayName || '',
          emailVerified: auth.currentUser?.emailVerified || false,
          plan: 'free',
          savedExams: [],
          planStartDate: null,
          planEndDate: null,
          createdAt: new Date().toISOString()
        };

        await setDoc(userRef, newUser);
        setUser(newUser);
        return newUser;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      const minimalUser = {
        id: userId,
        email: auth.currentUser?.email || '',
        name: auth.currentUser?.displayName || '',
        plan: 'free',
        savedExams: []
      };
      setUser(minimalUser);
      return minimalUser;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        fetchUserData(firebaseUser.uid);
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await fetchUserData(result.user.uid);
      return result.user;
    } catch (error) {
      console.error("Error during Google login:", error);
      throw error;
    }
  };

  const loginWithEmail = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      await fetchUserData(result.user.uid);
      return result.user;
    } catch (error) {
      console.error("Error during email login:", error);
      throw error;
    }
  };

  const signup = async (email, password, name) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: name });
      await sendEmailVerification(user);

      const newUserData = {
        id: user.uid,
        email,
        name,
        emailVerified: false,
        plan: 'free',
        savedExams: [],
        planStartDate: null,
        planEndDate: null,
        createdAt: new Date().toISOString()
      };

      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, newUserData);
      setUser(newUserData);

      return user;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      // Clear any local storage items
      localStorage.removeItem('currentExam');
      localStorage.removeItem('currentExamResult');
      localStorage.removeItem('reviewExam');
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  };

  const confirmPasswordResetAction = async (code, newPassword) => {
    try {
      await confirmPasswordReset(auth, code, newPassword);
    } catch (error) {
      console.error("Error confirming password reset:", error);
      throw error;
    }
  };

  const updateProfile = async (updates) => {
    if (!user) throw new Error('User not authenticated');

    try {
      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, updates);
      setUser(prev => ({ ...prev, ...updates }));
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  };

  const updateExamScore = async (examName, attempt, completeExam = null) => {
    if (!user) throw new Error('User not authenticated');
  
    try {
      const userRef = doc(db, 'users', user.id);
      const userDoc = await getDoc(userRef);
      const currentSavedExams = userDoc.data()?.savedExams || [];
  
      const isProUser = user.plan === 'pro';
      const exam = completeExam || currentSavedExams.find(e => e.name === examName);
      const domains = exam?.metadata?.domains?.sub || [];
      const isFirstDomain = attempt.domain === domains[0];
  
      if (!isProUser && !isFirstDomain && !attempt.isExamMode) {
        throw new Error('Pro subscription required for additional domain tests');
      }
  
      // Format attempt for storage
      const attemptForStorage = {
        id: attempt.id,
        score: attempt.score,
        date: attempt.date,
        timeTaken: attempt.timeTaken,
        answers: attempt.answers,
        flaggedQuestions: attempt.flaggedQuestions,
        completed: true
      };
  
      // Add exam mode specific data if present
      if (attempt.isExamMode) {
        attemptForStorage.isExamMode = true;
        attemptForStorage.domainScores = attempt.domainScores;
      }
  
      // Find or create exam
      const examIndex = currentSavedExams.findIndex(e => 
        e.certificationCode === attempt.certificationCode
      );
  
      if (examIndex >= 0) {
        // Update existing exam
        let existingExam = {...currentSavedExams[examIndex]};
        
        // Ensure both domains and exams arrays exist
        if (!existingExam.domains) {
          existingExam.domains = {};
        }
        if (!existingExam.exams) {
          existingExam.exams = [];
        }
  
        if (attempt.isExamMode) {
          // Add to exams array for exam mode attempts
          existingExam.exams = [
            attemptForStorage,
            ...existingExam.exams
          ];
        } else {
          // Add to domain attempts for practice tests
          if (!existingExam.domains[attempt.domain]) {
            existingExam.domains[attempt.domain] = {
              attempts: [],
              questions: existingExam.domains[attempt.domain]?.questions || [],
              generated: existingExam.domains[attempt.domain]?.generated || false,
              lastGeneratedDate: existingExam.domains[attempt.domain]?.lastGeneratedDate
            };
          }
  
          existingExam.domains[attempt.domain].attempts = [
            attemptForStorage,
            ...existingExam.domains[attempt.domain].attempts
          ];
        }
  
        // Update exam in currentSavedExams
        currentSavedExams[examIndex] = {
          ...existingExam,
          lastScore: attempt.score,
          lastAttemptDate: attempt.date,
          metadata: exam.metadata
        };
      } else {
        // Create new exam entry
        const newExam = {
          name: examName,
          certificationCode: attempt.certificationCode,
          metadata: exam.metadata,
          domains: {},
          exams: attempt.isExamMode ? [attemptForStorage] : [],
          lastScore: attempt.score,
          lastAttemptDate: attempt.date
        };
  
        if (!attempt.isExamMode) {
          newExam.domains[attempt.domain] = {
            attempts: [attemptForStorage],
            questions: [],
            generated: true,
            lastGeneratedDate: new Date().toISOString()
          };
        }
  
        currentSavedExams.push(newExam);
      }
  
      // Update Firestore
      await updateDoc(userRef, {
        savedExams: currentSavedExams
      });
  
      // Update local state
      setUser(prev => ({
        ...prev,
        savedExams: currentSavedExams
      }));
  
      return true;
    } catch (error) {
      console.error("Error updating exam score:", error);
      throw error;
    }
  };

  const deleteExam = async (examToDelete) => {
    if (!user) throw new Error('User not authenticated');

    try {
      const userRef = doc(db, 'users', user.id);
      
      // Filter out the exam to delete
      const updatedExams = user.savedExams.filter(exam => 
        exam.certificationCode !== examToDelete.metadata?.identifier?.certificationCode
      );

      // Update Firestore
      await updateDoc(userRef, {
        savedExams: updatedExams
      });

      // Update local state
      setUser(prev => ({
        ...prev,
        savedExams: updatedExams
      }));

      return true;
    } catch (error) {
      console.error("Error deleting exam:", error);
      throw error;
    }
  };

  const saveDraftAttempt = async (normalizedId, domain, draftData) => {
    if (!user) throw new Error('User not authenticated');

    try {
      const draftRef = doc(db, 'draft_attempts', `${user.id}_${normalizedId}_${domain}`);
      await setDoc(draftRef, {
        userId: user.id,
        normalizedId,
        domain,
        ...draftData,
        lastUpdated: new Date().toISOString()
      });
      return true;
    } catch (error) {
      console.error("Error saving draft:", error);
      throw error;
    }
  };

  const getDraftAttempt = async (normalizedId, domain) => {
    if (!user) return null;

    try {
      const draftRef = doc(db, 'draft_attempts', `${user.id}_${normalizedId}_${domain}`);
      const draftDoc = await getDoc(draftRef);
      return draftDoc.exists() ? draftDoc.data() : null;
    } catch (error) {
      console.error("Error getting draft:", error);
      return null;
    }
  };

  const deleteDraftAttempt = async (normalizedId, domain) => {
    if (!user) return;

    try {
      const draftRef = doc(db, 'draft_attempts', `${user.id}_${normalizedId}_${domain}`);
      await deleteDoc(draftRef);
    } catch (error) {
      console.error("Error deleting draft:", error);
    }
  };

  const saveCertificationMetadata = async (metadata) => {
    if (!user) throw new Error('User not authenticated');
  
    try {
      const userRef = doc(db, 'users', user.id);
      const userDoc = await getDoc(userRef);
      const currentSavedExams = userDoc.data()?.savedExams || [];
  
      // Check if certification already exists
      const certExists = currentSavedExams.some(exam => 
        exam.certificationCode === metadata.identifier.certificationCode
      );
  
      if (!certExists) {
        const newExam = {
          name: metadata.identifier.displayName,
          certificationCode: metadata.identifier.certificationCode,
          metadata: metadata,
          domains: {},
          createdAt: new Date().toISOString(),
          lastGeneratedDate: new Date().toISOString()
        };
  
        // Add new certification to savedExams
        const updatedExams = [...currentSavedExams, newExam];
  
        // Update Firestore
        await updateDoc(userRef, {
          savedExams: updatedExams
        });
  
        // Update local state
        setUser(prev => ({
          ...prev,
          savedExams: updatedExams
        }));
      }
  
      return true;
    } catch (error) {
      console.error("Error saving certification metadata:", error);
      throw error;
    }
  };

  const saveGeneratedTest = async (examMetadata, domain, questions) => {
    if (!user) throw new Error('User not authenticated');
  
    try {
      // Validate required data
      if (!examMetadata?.identifier?.certificationCode || 
          !examMetadata?.identifier?.displayName || 
          !domain || 
          !questions) {
        console.error('Invalid data:', { examMetadata, domain, questions });
        throw new Error('Missing required data for saving test');
      }
  
      const userRef = doc(db, 'users', user.id);
      const userDoc = await getDoc(userRef);
      const currentSavedExams = userDoc.data()?.savedExams || [];
  
      const certificationCode = examMetadata.identifier.certificationCode;
      
      // Find existing exam
      const existingExamIndex = currentSavedExams.findIndex(
        savedExam => savedExam.certificationCode === certificationCode
      );
  
      let updatedExams = [...currentSavedExams];
  
      if (existingExamIndex !== -1) {
        // Update existing exam
        const existingExam = {...currentSavedExams[existingExamIndex]};
        
        // Ensure domains object exists
        existingExam.domains = existingExam.domains || {};
        
        // Update the specific domain
        existingExam.domains[domain] = {
          questions,
          generated: true,
          attempts: existingExam.domains[domain]?.attempts || [],
          lastGeneratedDate: new Date().toISOString()
        };
  
        updatedExams[existingExamIndex] = {
          ...existingExam,
          name: examMetadata.identifier.displayName,
          metadata: examMetadata,
          certificationCode,
          lastGeneratedDate: new Date().toISOString()
        };
      } else {
        // Create new exam entry
        const newExam = {
          name: examMetadata.identifier.displayName,
          metadata: examMetadata,
          certificationCode,
          domains: {
            [domain]: {
              questions,
              generated: true,
              attempts: [],
              lastGeneratedDate: new Date().toISOString()
            }
          },
          totalAttempts: 0,
          lastGeneratedDate: new Date().toISOString()
        };
  
        updatedExams.push(newExam);
      }
  
      // Update Firestore with the new data
      await updateDoc(userRef, {
        savedExams: updatedExams.map(exam => ({
          ...exam,
          // Ensure all required fields are present
          name: exam.name,
          certificationCode: exam.certificationCode,
          metadata: exam.metadata,
          domains: exam.domains,
          lastGeneratedDate: exam.lastGeneratedDate,
          totalAttempts: exam.totalAttempts || 0
        }))
      });
  
      // Update local state
      setUser(prev => ({
        ...prev,
        savedExams: updatedExams
      }));
  
      return true;
    } catch (error) {
      console.error("Error saving generated test:", error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    loginWithGoogle,
    loginWithEmail,
    signup,
    logout,
    resetPassword,
    confirmPasswordReset: confirmPasswordResetAction,
    updateProfile,
    updateExamScore,
    deleteExam,
    saveDraftAttempt,
    getDraftAttempt,
    deleteDraftAttempt,
    saveGeneratedTest,
    saveCertificationMetadata,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;