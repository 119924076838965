import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import {
  BookOpen,
  Award,
  Target,
  Calendar,
  ChevronRight,
  AlertTriangle,
  Trash2,
} from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../ui/alert-dialog';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';

const AccountPage = () => {
  const { user, deleteExam } = useContext(UserContext);
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [examToDelete, setExamToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Calculate user stats
  const stats = {
    activeCerts: user?.savedExams?.length || 0,
    totalAttempts: user?.savedExams?.reduce((sum, exam) => {
      const domainAttempts = Object.values(exam.domains || {}).reduce(
        (domainSum, domain) => domainSum + (domain.attempts?.length || 0),
        0
      );
      return sum + domainAttempts;
    }, 0) || 0,
    lastActive: user?.savedExams?.reduce((latest, exam) => {
      const examDate = new Date(exam.lastAttemptDate || 0);
      return examDate > latest ? examDate : latest;
    }, new Date(0))
  };

  const handleDeleteExam = async () => {
    if (!examToDelete) return;
    
    try {
      setIsLoading(true);
      await deleteExam(examToDelete);
      setShowDeleteDialog(false);
      setExamToDelete(null);
    } catch (error) {
      console.error('Delete exam error:', error);
      setError('Failed to delete exam. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setExamToDelete(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 pb-16">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                My Practice Tests
              </h1>
              <p className="text-gray-600 mt-2">
                Manage your certification practice tests
              </p>
            </div>
            <Button
              onClick={() => navigate('/')}
              className="bg-gradient-to-r from-blue-600 to-purple-600 text-white"
            >
              <BookOpen className="w-4 h-4 mr-2" />
              New Practice Test
            </Button>
          </div>
        </div>

        {/* Stats Overview */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <Card className="bg-white/90 backdrop-blur-md">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-500">Active Certifications</p>
                  <p className="text-2xl font-bold">{stats.activeCerts}</p>
                </div>
                <div className="p-3 rounded-full bg-blue-100">
                  <Award className="w-6 h-6 text-blue-500" />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-white/90 backdrop-blur-md">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-500">Total Attempts</p>
                  <p className="text-2xl font-bold">{stats.totalAttempts}</p>
                </div>
                <div className="p-3 rounded-full bg-purple-100">
                  <Target className="w-6 h-6 text-purple-500" />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-white/90 backdrop-blur-md">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-500">Last Active</p>
                  <p className="text-2xl font-bold">
                    {stats.lastActive.getTime() > 0 
                      ? stats.lastActive.toLocaleDateString() 
                      : 'Never'}
                  </p>
                </div>
                <div className="p-3 rounded-full bg-green-100">
                  <Calendar className="w-6 h-6 text-green-500" />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Certification Cards */}
        <div className="space-y-4">
          {user?.savedExams?.length > 0 ? (
            user.savedExams.map((exam) => (
              <Card key={exam.metadata?.identifier?.certificationCode} className="bg-white/90 backdrop-blur-md">
                <CardContent className="p-6">
                  <div className="flex items-center justify-between">
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold">
                        {exam.metadata?.identifier?.displayName}
                      </h3>
                      <div className="flex items-center mt-1 text-sm text-gray-500">
                        <Target className="w-4 h-4 mr-1" />
                        <span>
                          {Object.values(exam.domains || {}).reduce(
                            (sum, domain) => sum + (domain.attempts?.length || 0),
                            0
                          )} attempts across {Object.keys(exam.domains || {}).length} domains
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Button
                        onClick={() => {
                          localStorage.setItem('currentExam', JSON.stringify({
                            exam: {
                              ...exam,
                              content: {
                                mode: 'practice',
                                status: 'pending'
                              }
                            }
                          }));
                          navigate(`/test-mode/${exam.metadata.identifier.certificationCode}`);
                        }}
                        className="bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                      >
                        View Practice Tests
                        <ChevronRight className="w-4 h-4 ml-2" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => {
                          setExamToDelete(exam);
                          setShowDeleteDialog(true);
                        }}
                      >
                        <Trash2 className="w-5 h-5 text-red-500" />
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))
          ) : (
            <div className="text-center py-12">
              <Award className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                No practice tests yet
              </h3>
              <p className="text-gray-500 mb-4">
                Generate your first practice test to start tracking your progress
              </p>
              <Button
                onClick={() => navigate('/')}
                className="bg-gradient-to-r from-blue-600 to-purple-600 text-white"
              >
                Generate Test
                <ChevronRight className="w-4 h-4 ml-2" />
              </Button>
            </div>
          )}
        </div>

        {/* Delete Dialog */}
        <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle className="flex items-center">
                <AlertTriangle className="w-5 h-5 text-red-500 mr-2" />
                Delete Practice Test
              </AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete this practice test and all its attempts?
                This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleCancelDelete} disabled={isLoading}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDeleteExam}
                className="bg-red-500 text-white hover:bg-red-600"
                disabled={isLoading}
              >
                {isLoading ? 'Deleting...' : 'Delete'}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Error Message */}
        {error && (
          <div className="fixed bottom-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            <p>{error}</p>
            <button
              className="absolute top-0 bottom-0 right-0 px-4"
              onClick={() => setError(null)}
            >
              <span className="sr-only">Close</span>
              ×
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountPage;