import React, { useState, useEffect } from 'react';
import { ThumbsUp, ThumbsDown, Loader2 } from 'lucide-react';
import { Button } from '../ui/button';
import { 
  updateDoc, 
  doc, 
  getDoc, 
  arrayUnion,
  setDoc
} from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { getAuth } from 'firebase/auth';

const QuestionFeedback = ({ 
  questionId,
  examId,
  onError
}) => {
  // Track both the current vote and loading state
  const [currentVote, setCurrentVote] = useState(null); // 'up', 'down', or null
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Load existing feedback when component mounts or questionId changes
  useEffect(() => {
    const loadFeedback = async () => {
      if (!questionId || !examId) return;

      try {
        const examRef = doc(db, 'feedback', examId);
        const examDoc = await getDoc(examRef);
        
        if (examDoc.exists()) {
          const data = examDoc.data();
          const questionFeedback = data.feedback?.[questionId];
          
          // Set initial vote based on existing feedback
          if (questionFeedback) {
            if (questionFeedback.upvotes > 0) {
              setCurrentVote('up');
            } else if (questionFeedback.downvotes > 0) {
              setCurrentVote('down');
            }
          }
        } else {
          // Create the document if it doesn't exist
          await setDoc(examRef, {
            feedback: {},
            userId: getAuth().currentUser?.uid
          });
        }
      } catch (error) {
        console.error('Error loading feedback:', error);
        onError?.('Failed to load feedback status');
      }
    };

    loadFeedback();
  }, [questionId, examId]);

  const handleFeedback = async (newVote) => {
    if (!examId || !questionId) return;
  
    try {
      setIsSubmitting(true);
      const auth = getAuth();
      const userId = auth.currentUser?.uid;
  
      if (!userId) {
        throw new Error('User not authenticated');
      }
  
      const timestamp = new Date().toISOString();

      // If clicking the same button again, remove the vote
      const finalVote = currentVote === newVote ? null : newVote;
      
      // Update feedback in Firebase
      const feedbackUpdate = {
        [`feedback.${questionId}`]: {
          upvotes: finalVote === 'up' ? 1 : 0,
          downvotes: finalVote === 'down' ? 1 : 0,
          lastVoteAt: finalVote ? timestamp : null,
          lastUpdateAt: timestamp,
          history: arrayUnion({
            type: finalVote || 'clear',
            timestamp,
            userId
          })
        }
      };
  
      const examRef = doc(db, 'feedback', examId);
      await updateDoc(examRef, feedbackUpdate);
  
      // Update local state
      setCurrentVote(finalVote);
  
    } catch (error) {
      console.error('Error updating feedback:', error);
      onError?.(error.message || 'Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <Button
        variant="ghost"
        size="sm"
        className={`p-2 hover:bg-gray-100 transition-colors ${
          currentVote === 'up' ? 'text-green-600' : 'text-gray-500'
        }`}
        onClick={() => handleFeedback('up')}
        disabled={isSubmitting}
        title={currentVote === 'up' ? 'Remove helpful vote' : 'Mark as helpful'}
      >
        {isSubmitting ? (
          <Loader2 className="h-5 w-5 animate-spin" />
        ) : (
          <ThumbsUp 
            className={`h-5 w-5 transition-all duration-200 ${
              currentVote === 'up' ? 'fill-current scale-110' : ''
            }`} 
          />
        )}
      </Button>
      <Button
        variant="ghost"
        size="sm"
        className={`p-2 hover:bg-gray-100 transition-colors ${
          currentVote === 'down' ? 'text-red-600' : 'text-gray-500'
        }`}
        onClick={() => handleFeedback('down')}
        disabled={isSubmitting}
        title={currentVote === 'down' ? 'Remove unhelpful vote' : 'Mark as unhelpful'}
      >
        {isSubmitting ? (
          <Loader2 className="h-5 w-5 animate-spin" />
        ) : (
          <ThumbsDown 
            className={`h-5 w-5 transition-all duration-200 ${
              currentVote === 'down' ? 'fill-current scale-110' : ''
            }`} 
          />
        )}
      </Button>
    </div>
  );
};

export default QuestionFeedback;