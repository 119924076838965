const ENV = {
    development: {
      API_ENDPOINT: 'http://localhost:4000',
    },
    production: {
      API_ENDPOINT: 'https://api.practicequiz.ai',
    },
    // You could add more environments like staging if needed
  };

const getEnvironmentConfig = () => {
// Default to 'development' if REACT_APP_ENV is not set
const env = process.env.REACT_APP_ENV || 'development';
return ENV[env] || ENV.development;
};

// Export configuration values
export const config = getEnvironmentConfig();

// Export individual values for convenience
export const API_ENDPOINT = config.API_ENDPOINT;