import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Search, Brain, Star, Clock, Shield } from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Input } from '../ui/input';

const FAQPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState('all');
  const [expandedItems, setExpandedItems] = useState(new Set());

  const categories = [
    { id: 'all', name: 'All Questions', icon: Star },
    { id: 'general', name: 'General', icon: Brain },
    { id: 'pricing', name: 'Pricing', icon: Clock },
    { id: 'security', name: 'Security', icon: Shield },
  ];

  const faqItems = [
    {
      id: 1,
      category: 'general',
      question: 'What is PracticeQuiz.ai?',
      answer: 'PracticeQuiz.ai is an AI-powered learning platform that generates personalized practice tests for various certifications and subjects. Our advanced AI technology creates unique questions tailored to your learning needs.'
    },
    {
      id: 2,
      category: 'general',
      question: 'How does the AI generate questions?',
      answer: 'Our AI uses advanced language models to analyze certification requirements and create relevant, challenging questions. Each question is designed to test your understanding and help you prepare for the actual exam.'
    },
    {
      id: 3,
      category: 'pricing',
      question: 'Whats included in the lifetime access?',
      answer: 'Lifetime access includes unlimited practice tests, detailed performance analytics, and all future platform updates. You will never have to pay again!'
    },
    {
      id: 4,
      category: 'security',
      question: 'How do you protect my data?',
      answer: 'We use industry-standard encryption and security measures to protect your personal information and test results. Your data is never shared with third parties without your explicit consent.'
    }
  ];

  const toggleItem = (id) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const filteredFAQs = faqItems.filter(item => {
    const matchesSearch = item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         item.answer.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = activeCategory === 'all' || item.category === activeCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-gray-600 text-lg">
            Find answers to common questions about PracticeQuiz.ai
          </p>
        </div>

        <div className="mb-8">
          <div className="relative">
            <Input
              type="text"
              placeholder="Search questions..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-12 pr-4 py-3 rounded-full bg-white/90 backdrop-blur-md"
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          {categories.map(({ id, name, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setActiveCategory(id)}
              className={`p-4 rounded-xl transition-all ${
                activeCategory === id
                  ? 'bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-lg scale-105'
                  : 'bg-white/90 text-gray-600 hover:bg-white/95'
              }`}
            >
              <Icon className={`w-6 h-6 mx-auto mb-2 ${
                activeCategory === id ? 'text-white' : 'text-gray-500'
              }`} />
              <span className="text-sm font-medium">{name}</span>
            </button>
          ))}
        </div>

        <div className="space-y-4">
          <AnimatePresence>
            {filteredFAQs.map((item) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <Card className="overflow-hidden bg-white/90 backdrop-blur-md">
                  <CardContent className="p-0">
                    <button
                      onClick={() => toggleItem(item.id)}
                      className="w-full text-left px-6 py-4 flex items-center justify-between"
                    >
                      <span className="font-medium text-gray-800">{item.question}</span>
                      <ChevronDown
                        className={`w-5 h-5 text-gray-500 transition-transform ${
                          expandedItems.has(item.id) ? 'transform rotate-180' : ''
                        }`}
                      />
                    </button>
                    <AnimatePresence>
                      {expandedItems.has(item.id) && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0 }}
                          className="overflow-hidden"
                        >
                          <div className="px-6 py-4 bg-gray-50">
                            <p className="text-gray-600">{item.answer}</p>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;