import React from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from '../../ui/card';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const CookiePolicyPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 py-16 px-4">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Link to="/legal" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-6">
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </Link>
          <h1 className="text-4xl font-bold text-gray-800 mb-6">Cookie Policy</h1>
          <p className="text-gray-600 mb-4">Last Updated: November 14, 2024</p>
        </motion.div>
        <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl overflow-hidden">
          <CardContent className="p-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="space-y-8"
            >
              <section>
                <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                <p className="mb-4">This Cookie Policy explains how PracticeQuiz.ai ("we", "us", "our") uses cookies and similar technologies when you visit our website. This policy should be read alongside our Privacy Policy and Terms of Service.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">2. What Are Cookies?</h2>
                <p className="mb-4">Cookies are small text files that are placed on your device when you visit our website. They help us recognize your device and provide various features and functionality.</p>
                <p className="mb-4">We use both session cookies (which expire when you close your browser) and persistent cookies (which remain on your device until you delete them or they expire).</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">3. Types of Cookies We Use</h2>
                
                <h3 className="text-xl font-medium mb-2">3.1 Essential Cookies</h3>
                <p className="mb-4">These cookies are necessary for the website to function properly. They enable basic functions like page navigation and access to secure areas of the website.</p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>Authentication cookies</li>
                  <li>Session management</li>
                  <li>Security cookies</li>
                </ul>

                <h3 className="text-xl font-medium mb-2">3.2 Performance Cookies</h3>
                <p className="mb-4">These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.</p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>Analytics cookies</li>
                  <li>Load balancing</li>
                  <li>Response time tracking</li>
                </ul>

                <h3 className="text-xl font-medium mb-2">3.3 Functionality Cookies</h3>
                <p className="mb-4">These cookies allow the website to remember choices you make and provide enhanced features.</p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>Language preferences</li>
                  <li>User preferences</li>
                  <li>Quiz progress tracking</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">4. Third-Party Cookies</h2>
                <p className="mb-4">Some cookies are placed by third-party services that appear on our pages. We use these services to:</p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>Analyze site traffic (Google Analytics)</li>
                  <li>Process payments (if applicable)</li>
                  <li>Provide social media features</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">5. Cookie Management</h2>
                
                <h3 className="text-xl font-medium mb-2">5.1 Your Choices</h3>
                <p className="mb-4">You have the right to choose whether to accept or reject cookies. You can:</p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>Use our cookie consent banner to manage preferences</li>
                  <li>Modify your browser settings to reject cookies</li>
                  <li>Delete cookies that have already been set</li>
                </ul>

                <h3 className="text-xl font-medium mb-2">5.2 Browser Settings</h3>
                <p className="mb-4">Most web browsers allow control of cookies through settings. Find out how to manage cookies on popular browsers:</p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li><span className="font-medium">Google Chrome:</span> Settings → Privacy and Security → Cookies</li>
                  <li><span className="font-medium">Mozilla Firefox:</span> Options → Privacy & Security → Cookies</li>
                  <li><span className="font-medium">Safari:</span> Preferences → Privacy → Cookies</li>
                  <li><span className="font-medium">Microsoft Edge:</span> Settings → Privacy & Security → Cookies</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">6. Essential Cookies Notice</h2>
                <p className="mb-4">Please note that essential cookies are required for the operation of our website. You can opt out of all other cookies, but disabling essential cookies may affect the functionality of the website.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">7. Updates to This Policy</h2>
                <p className="mb-4">We may update this Cookie Policy from time to time. The latest version will always be posted on our website, with the effective date listed at the top of the page.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
                <p className="mb-4">If you have any questions about our use of cookies, please contact us at:</p>
                <ul className="list-none mb-4 space-y-2">
                  <li>Email: [Your Email]</li>
                  <li>Address: [Your Address]</li>
                  <li>Data Protection Officer: [DPO Contact]</li>
                </ul>
              </section>
            </motion.div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CookiePolicyPage;