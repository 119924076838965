import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { CheckCircle, ArrowRight, Sparkles } from 'lucide-react';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="w-full max-w-md"
      >
        <Card className="bg-white/90 backdrop-blur-md">
          <CardContent className="p-8 text-center">
            <div className="mb-8">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ 
                  type: "spring",
                  stiffness: 260,
                  damping: 20 
                }}
              >
                <CheckCircle className="w-16 h-16 text-green-500 mx-auto" />
              </motion.div>
            </div>

            <h1 className="text-2xl font-bold text-gray-800 mb-4">
              Welcome to Premium!
            </h1>

            <p className="text-gray-600 mb-6">
              Thank you for subscribing to PracticeQuiz.ai. You now have unlimited access to all our premium features.
            </p>

            <div className="bg-blue-50 rounded-lg p-4 mb-8">
              <h2 className="text-lg font-semibold text-blue-700 mb-3 flex items-center justify-center">
                <Sparkles className="w-5 h-5 mr-2" />
                What's Next?
              </h2>
              <ul className="text-sm text-blue-600 space-y-2">
                <li>✓ Unlimited practice tests</li>
                <li>✓ Detailed performance analytics</li>
                <li>✓ Custom study plans</li>
                <li>✓ Priority support</li>
              </ul>
            </div>

            <div className="space-y-3">
              <Button
                onClick={() => navigate('/')}
                className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white"
              >
                Start Your First Test
                <ArrowRight className="ml-2 w-4 h-4" />
              </Button>
              
              <Button
                onClick={() => navigate('/account')}
                variant="outline"
                className="w-full"
              >
                Go to Dashboard
              </Button>
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default SubscriptionSuccess;