import React from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from '../ui/card';
import { ArrowRight, Shield, Lock, FileText, Scale } from 'lucide-react';
import { Link } from 'react-router-dom';

const LegalHub = () => {
  const legalDocuments = [
    {
      title: 'Terms of Service',
      description: 'Our terms and conditions for using PracticeQuiz.ai',
      icon: Scale,
      path: '/legal/terms',
      lastUpdated: 'November 20, 2024'
    },
    {
      title: 'Privacy Policy',
      description: 'How we collect, use, and protect your data',
      icon: Lock,
      path: '/legal/privacy',
      lastUpdated: 'November 20, 2024'
    },
    {
      title: 'Cookie Policy',
      description: 'Information about how we use cookies and similar technologies',
      icon: Shield,
      path: '/legal/cookies',
      lastUpdated: 'November 20, 2024'
    },
    {
      title: 'Acceptable Use Policy',
      description: 'Guidelines for proper use of our platform',
      icon: FileText,
      path: '/legal/acceptable-use',
      lastUpdated: 'November 20, 2024'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-4">
            Legal Information
          </h1>
          <p className="text-gray-600 text-lg">
            Our commitment to transparency and user privacy
          </p>
        </div>

        <div className="grid gap-6">
          {legalDocuments.map((doc, index) => {
            const Icon = doc.icon;
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link to={doc.path}>
                  <Card className="group hover:shadow-lg transition-all duration-300 bg-white/90 backdrop-blur-md">
                    <CardContent className="p-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="p-3 rounded-full bg-gradient-to-r from-blue-500 to-purple-600">
                            <Icon className="w-6 h-6 text-white" />
                          </div>
                          <div>
                            <h2 className="text-xl font-semibold text-gray-800 mb-1">
                              {doc.title}
                            </h2>
                            <p className="text-sm text-gray-500">
                              {doc.description}
                            </p>
                            <p className="text-xs text-gray-400 mt-1">
                              Last updated: {doc.lastUpdated}
                            </p>
                          </div>
                        </div>
                        <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-purple-600 transform group-hover:translate-x-1 transition-all" />
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </motion.div>
            );
          })}
        </div>

        <div className="mt-12 p-6 bg-gradient-to-r from-blue-500 to-purple-600 rounded-2xl text-white">
          <h2 className="text-xl font-semibold mb-2">Need Help?</h2>
          <p className="mb-4">
            If you have any questions about our legal documents or need clarification, our support team is here to help.
          </p>
          <Link 
            to="/Contact"
            className="inline-flex items-center space-x-2 bg-white text-blue-600 px-4 py-2 rounded-full hover:bg-blue-50 transition-colors"
          >
            <span>Contact Support</span>
            <ArrowRight className="w-4 h-4" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LegalHub;