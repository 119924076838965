import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const AlertDialog = ({ open, onClose, children }) => {
  if (!open) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          onClick={(e) => e.stopPropagation()}
          className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4"
        >
          {children}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const AlertDialogContent = ({ children, className = '' }) => (
  <div className={`p-6 ${className}`}>{children}</div>
);

const AlertDialogHeader = ({ children, className = '' }) => (
  <div className={`mb-6 ${className}`}>{children}</div>
);

const AlertDialogTitle = ({ children, className = '' }) => (
  <h2 className={`text-xl font-semibold mb-2 ${className}`}>{children}</h2>
);

const AlertDialogDescription = ({ children, className = '' }) => (
  <p className={`text-gray-600 ${className}`}>{children}</p>
);

const AlertDialogFooter = ({ children, className = '' }) => (
  <div className={`flex items-center justify-end space-x-3 mt-6 ${className}`}>
    {children}
  </div>
);

const AlertDialogAction = ({ children, onClick, className = '' }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors ${className}`}
  >
    {children}
  </button>
);

const AlertDialogCancel = ({ children, onClick, className = '' }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg text-gray-600 hover:bg-gray-100 transition-colors ${className}`}
  >
    {children}
  </button>
);

export {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogCancel,
};