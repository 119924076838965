import React from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from '../../ui/card';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const TermsOfUsePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 py-16 px-4">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Link to="/legal" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-6">
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </Link>
          <h1 className="text-4xl font-bold text-gray-800 mb-6">Terms of Use</h1>
        </motion.div>
        <Card className="bg-white/90 backdrop-blur-md shadow-lg rounded-2xl overflow-hidden">
          <CardContent className="p-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
              <p className="mb-4">By accessing and using this service, you accept and agree to be bound by the terms and provision of this agreement.</p>

              <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
              <p className="mb-4">Our service provides AI-powered learning tools and practice exams. We reserve the right to modify or discontinue the service at any time without notice.</p>

              <h2 className="text-2xl font-semibold mb-4">3. User Conduct</h2>
              <p className="mb-4">You agree to use our service for lawful purposes only and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.</p>

              <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
              <p className="mb-4">The content, organization, graphics, design, and other matters related to the site are protected under applicable copyrights and other proprietary laws. Copying, redistribution, use or publication either for free or for monetary gain is strictly prohibited.</p>

              <h2 className="text-2xl font-semibold mb-4">5. Termination of Agreement</h2>
              <p className="mb-4">We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

              <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
              <p className="mb-4">In no event shall we be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.</p>
            </motion.div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default TermsOfUsePage;