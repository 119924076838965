import React, { useState, useEffect } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription } from '../ui/alert-dialog';
import { Clock, AlertTriangle } from 'lucide-react';

const ExamTimer = ({ startTime, onTimeUp }) => {
  const EXAM_DURATION = 60 * 60; // 60 minutes in seconds
  const [timeLeft, setTimeLeft] = useState(EXAM_DURATION);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const elapsed = Math.floor((now - startTime) / 1000);
      const remaining = EXAM_DURATION - elapsed;

      if (remaining <= 0) {
        clearInterval(interval);
        onTimeUp();
        return;
      }

      setTimeLeft(remaining);

      // Show alerts at specific intervals
      switch (remaining) {
        case 30 * 60: // 30 minutes
          setAlertMessage('30 minutes remaining in the exam.');
          setShowAlert(true);
          break;
        case 15 * 60: // 15 minutes
          setAlertMessage('15 minutes remaining in the exam.');
          setShowAlert(true);
          break;
        case 10 * 60: // 10 minutes
          setAlertMessage('10 minutes remaining in the exam.');
          setShowAlert(true);
          break;
        case 5 * 60: // 5 minutes
          setAlertMessage('5 minutes remaining in the exam.');
          setShowAlert(true);
          break;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, onTimeUp]);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getTimeColor = () => {
    if (timeLeft <= 300) return 'text-red-500'; // Last 5 minutes
    if (timeLeft <= 600) return 'text-orange-500'; // Last 10 minutes
    return 'text-blue-500';
  };

  return (
    <div className="flex items-center">
      <Clock className={`w-5 h-5 mr-2 ${getTimeColor()}`} />
      <span className={`font-mono font-bold ${getTimeColor()}`}>
        {formatTime(timeLeft)}
      </span>

      <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center">
              <AlertTriangle className="w-5 h-5 text-orange-500 mr-2" />
              Time Alert
            </AlertDialogTitle>
            <AlertDialogDescription>
              {alertMessage}
            </AlertDialogDescription>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ExamTimer;