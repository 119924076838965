import React, { useState, useContext, useEffect, useMemo } from 'react';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import {
  BookOpen,
  Crown,
  AlertTriangle,
  Play,
  Lock,
  RefreshCw,
  Trophy,
  Info
} from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { API_ENDPOINT } from '../../config/env';
import { checkAllDomainsGenerated, prepareExamModeQuestions } from '../../lib/examMode';
import { useParams } from 'react-router-dom';

const TestModeSelector = () => {
  const { user, saveGeneratedTest } = useContext(UserContext);
  const { certificationId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [generatingStates, setGeneratingStates] = useState({});
  const [generatedStates, setGeneratedStates] = useState({});
  const [isGeneratingExam, setIsGeneratingExam] = useState(false);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  // Get exam data from localStorage
  const examData = useMemo(() => {
    try {
      const currentExam = localStorage.getItem('currentExam');
      if (!currentExam) return null;
      const parsed = JSON.parse(currentExam);
      return parsed?.exam || null;
    } catch (error) {
      console.error('Error parsing exam data:', error);
      return null;
    }
  }, []);

  // Extract required data
  const domains = examData?.metadata?.domains?.sub || [];
  const weightage = examData?.metadata?.domains?.weightage || {};
  const certCode = examData?.metadata?.identifier?.certificationCode;
  const isProUser = user?.plan === 'pro';

  // Initialize generated states from cache
  useEffect(() => {
    if (!certCode || !domains.length) return;
    
    try {
      const savedStates = {};
      domains.forEach(domain => {
        const savedExam = user?.savedExams?.find(exam => 
          exam.certificationCode === certCode &&
          exam.domains?.[domain]?.questions
        );
        savedStates[domain] = !!savedExam;
      });
      setGeneratedStates(savedStates);
    } catch (error) {
      console.error('Error checking saved tests:', error);
    }
  }, [certCode, domains, user?.savedExams]);

  // Calculate all domains generated status
  const allDomainsGenerated = useMemo(() => {
    if (!examData || !domains.length) return false;
    return domains.every(domain => generatedStates[domain]);
  }, [examData, domains, generatedStates]);

  const handleDomainSelect = async (domain, index) => {
    if (!isProUser && index > 0) {
      setShowUpgradeDialog(true);
      return;
    }

    if (generatingStates[domain]) return;

    try {
      setGeneratingStates(prev => ({ ...prev, [domain]: true }));
      setError(null);

      const cacheKey = `${certCode}_${domain}`;
      const cachedQuestions = JSON.parse(localStorage.getItem('cachedQuestions') || '{}');

      if (cachedQuestions[cacheKey]) {
        // Set generating state to show loading
        setGeneratingStates(prev => ({ ...prev, [domain]: true }));

        // Simulate loading for 10 seconds
        await new Promise(resolve => setTimeout(resolve, 10000));

        setGeneratedStates(prev => ({ ...prev, [domain]: true }));
        setGeneratingStates(prev => {
          const updated = { ...prev };
          delete updated[domain];
          return updated;
        });
        return;
      }

      const auth = getAuth();
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(`${API_ENDPOINT}/generate-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken
        },
        body: JSON.stringify({
          prompt: examData.metadata.identifier.displayName,
          metadata: examData.metadata,
          domain: domain,
          isFirstDomain: index === 0
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate test');
      }

      const data = await response.json();
      const questions = JSON.parse(data.result).questions;

      // Update cache
      cachedQuestions[cacheKey] = questions;
      localStorage.setItem('cachedQuestions', JSON.stringify(cachedQuestions));

      // Save to backend
      await saveGeneratedTest(examData.metadata, domain, questions);

      // Update state
      setGeneratedStates(prev => ({ ...prev, [domain]: true }));

    } catch (error) {
      console.error('Error generating test:', error);
      setError(error.message || 'Failed to generate test. Please try again.');
    } finally {
      setGeneratingStates(prev => {
        const updated = { ...prev };
        delete updated[domain];
        return updated;
      });
    }
  };

  const handleStartPractice = async (domain) => {
    try {
      const savedExam = user?.savedExams?.find(exam => 
        exam.certificationCode === certCode &&
        exam.domains?.[domain]?.questions
      );
  
      if (!savedExam?.domains?.[domain]?.questions) {
        setError('No practice test data found. Please try generating the test again.');
        return;
      }
  
      const practiceExam = {
        exam: {
          name: examData?.metadata?.identifier?.displayName,
          metadata: examData?.metadata,
          content: {
            questions: savedExam.domains[domain].questions,
            mode: 'practice',
            domain: domain,
            status: 'complete',
            generated: true,
            totalQuestions: savedExam.domains[domain].questions.length,
            completedBatches: 1,
            totalBatches: 1
          }
        },
        startTime: Date.now()
      };
      
      localStorage.setItem('currentExam', JSON.stringify(practiceExam));
      navigate(`/practice-test/${certificationId}`);
    } catch (error) {
      console.error('Error starting practice:', error);
      setError('Failed to start test. Please try again.');
    }
  };

  const handleStartExamMode = async () => {
    if (!allDomainsGenerated) return;

    try {
      setIsGeneratingExam(true);
      setError(null);

      const examModeQuestions = prepareExamModeQuestions(examData);
      
      const practiceExam = {
        exam: {
          name: examData?.metadata?.identifier?.displayName,
          metadata: examData?.metadata,
          content: {
            questions: examModeQuestions,
            mode: 'exam',
            status: 'complete',
            generated: true,
            totalQuestions: examModeQuestions.length,
            completedBatches: 1,
            totalBatches: 1
          }
        },
        startTime: Date.now()
      };
      
      localStorage.setItem('currentExam', JSON.stringify(practiceExam));
      navigate(`/practice-test/${certificationId}`);
    } catch (error) {
      console.error('Error starting exam mode:', error);
      setError('Failed to start exam mode. Please try again.');
    } finally {
      setIsGeneratingExam(false);
    }
  };

  if (!examData) {
    navigate('/');
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 pt-32 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">
            {examData?.metadata?.identifier?.displayName}
          </h1>
          <p className="text-gray-600">
            Choose a domain to practice or try full exam mode
          </p>
        </div>

        <Card className="bg-white/90 backdrop-blur-md">
          <CardContent className="p-6">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <BookOpen className="w-6 h-6 text-blue-500 mr-2" />
                <h2 className="text-xl font-semibold">Practice by Domain</h2>
              </div>
              {!isProUser && (
                <Crown className="w-6 h-6 text-yellow-500" />
              )}
            </div>
            
            {error && (
              <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-center text-red-700">
                <AlertTriangle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}

            <div className="space-y-3">
              {domains.map((domain, index) => {
                const isLocked = !isProUser && index > 0;
                const isGenerating = generatingStates[domain];
                const isGenerated = generatedStates[domain];

                return (
                  <div key={domain} className="p-4 border rounded-lg bg-white">
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <span className="font-medium">{domain}</span>
                          {isLocked && (
                            <Crown className="w-4 h-4 text-yellow-500" />
                          )}
                        </div>
                        <p className="text-sm text-gray-500">
                          {weightage[domain]}% of exam
                        </p>
                      </div>

                      <div className="flex items-center gap-2">
                      {isLocked ? (
                        <Button
                          onClick={() => setShowUpgradeDialog(true)}
                          className="bg-purple-500 hover:bg-purple-600 text-white"
                        >
                          <Lock className="w-4 h-4 mr-2" />
                          Unlock Domain
                        </Button>
                      ) : isGenerating ? (
                        <Button disabled className="bg-blue-600 text-white">
                          <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                          Generating...
                        </Button>
                      ) : isGenerated ? (
                        <Button
                          onClick={() => handleStartPractice(domain)}
                          className="bg-green-600 hover:bg-green-700 text-white"
                        >
                          <Play className="w-4 h-4 mr-2" />
                          Start Practice
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleDomainSelect(domain, index)}
                          className="bg-blue-600 hover:bg-blue-700 text-white"
                        >
                          <RefreshCw className="w-4 h-4 mr-2" />
                          Generate Test
                        </Button>
                      )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Exam Mode */}
            <div className="mt-6 bg-gradient-to-r from-blue-500 to-purple-500 p-0.5 rounded-lg">
              <div className="bg-white/90 backdrop-blur-md p-6 rounded-lg">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <Trophy className="w-6 h-6 text-blue-500 mr-2" />
                    <h3 className="text-lg font-semibold">Exam Mode</h3>
                  </div>
                  {!isProUser && <Crown className="w-6 h-6 text-yellow-500" />}
                </div>

                {!isProUser ? (
                  <div>
                    <p className="text-gray-600 text-sm mb-4">
                      Experience a real exam simulation with questions from all domains.
                      Available for Pro users only.
                    </p>
                    <Button
                      onClick={() => setShowUpgradeDialog(true)}
                      className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white"
                    >
                      <Crown className="w-4 h-4 mr-2" />
                      Upgrade to Pro
                    </Button>
                  </div>
                ) : !allDomainsGenerated ? (
                  <div className="flex items-start space-x-2 mb-4">
                    <AlertTriangle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
                    <p className="text-sm text-gray-600">
                      Generate all domain practice tests first to unlock exam mode
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-gray-600 text-sm mb-4">
                      Test your knowledge with questions from all domains in exam-like conditions
                    </p>
                    <Button
                      onClick={handleStartExamMode}
                      disabled={isGeneratingExam}
                      className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white"
                    >
                      {isGeneratingExam ? (
                        <>
                          <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                          Preparing Exam...
                        </>
                      ) : (
                        <>
                          <Trophy className="w-4 h-4 mr-2" />
                          Start Full Exam
                        </>
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-6 flex items-start space-x-2 text-sm text-gray-500">
              <AlertTriangle className="w-4 h-4 mt-0.5 flex-shrink-0" />
              <p>
                {isProUser 
                  ? "Practice tests are generated based on the latest exam objectives"
                  : "Free users can access one domain. Upgrade to practice all domains and access exam mode."}
              </p>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Upgrade Dialog */}
      <AlertDialog open={showUpgradeDialog} onOpenChange={setShowUpgradeDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center">
              <Crown className="w-5 h-5 text-yellow-500 mr-2" />
              Upgrade to Access All Features
            </AlertDialogTitle>
            <AlertDialogDescription>
              Upgrade to unlock all domains, exam mode, and enhance your exam preparation.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => navigate('/pricing')}
              className="bg-gradient-to-r from-purple-500 to-blue-500 text-white"
            >
              View Pricing
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default TestModeSelector;